import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import useLongPress from "../hooks/UseLongPress";

const StyledActionButton = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.button.fontSize,
    borderRadius: theme.shape.borderRadius,
    fontWeight: theme.typography.button.fontWeight,
    textAlign: "center",
    width: "100%",
    height: "100%",
    padding: 20,
  },
}))(Button);

const ActionButton = ({ action, loading, disabled, onClick, onLongClick, children, ...props }) => {
  const useStyles = makeStyles((theme) => ({
    button: {
      backgroundColor: theme.palette[action === "danger" ? "error" : action].main,
      "&:hover": {
        backgroundColor: theme.palette[action === "danger" ? "error" : action].dark,
      },
    },
    disabled: {
      backgroundColor: theme.palette.grey[500],
    },
    progress: {
      color: theme.palette[action === "danger" ? "error" : action].main,
      position: "absolute",
      top: "calc(50% - 30px)",
      left: "calc(50% - 30px)",
    },
  }));

  const classes = useStyles();
  const longPressEvent = useLongPress(onLongClick, onClick, { shouldPreventDefault: true, delay: 400 });

  return (
    <StyledActionButton
      {...longPressEvent}
      disabled={disabled || loading}
      className={disabled || loading ? classes.disabled : classes.button}
      variant="outlined"
      disableElevation
      {...props}
    >
      <Typography variant="h5">{children}</Typography>
      {loading && <CircularProgress thickness={5} size={60} className={classes.progress} />}
    </StyledActionButton>
  );
};

ActionButton.propTypes = {
  action: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onLongClick: PropTypes.func,
  children: PropTypes.any,
};

ActionButton.defaultProps = {
  action: "primary",
  onClick: () => {},
  onLongClick: () => {},
};

export default ActionButton;
