import React from "react";
import { orderBy } from "lodash";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { GET_PARCELS } from "../../apollo/queries";
import FarmCard from "../cards/FarmCard";
import Modal from "../modals/Modal";
import CardGridList from "../common/CardGridList";

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 20,
    paddingLeft: 5,
    paddingRight: 5,
  },
  leftContainer: {
    textAlign: "left",
  },
  rightContainer: {
    textAlign: "right",
  },
  subtitle: {
    position: "relative",
    top: -25,
    opacity: 0.9,
  },
}));

const StyledActionButton = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.button.fontSize,
    borderRadius: theme.shape.borderRadius,
    fontWeight: theme.typography.button.fontWeight,
    textAlign: "center",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.terciary.main,
    "&:hover": {
      backgroundColor: theme.palette.terciary.dark,
    },
    maxHeight: 111,
  },
}))(Button);

const OriginButton = React.forwardRef(({ onChange }, ref) => {
  const { data } = useQuery(GET_PARCELS);
  const [parcel, setParcel] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  React.useImperativeHandle(ref, () => ({
    showDialog() {
      setOpen(true);
    },
  }));

  const onClose = (value) => {
    setOpen(false);
    if (value) {
      setParcel(value);
      onChange(value);
    }
  };

  return (
    <React.Fragment>
      <StyledActionButton variant="outlined" onClick={() => setOpen(true)}>
        {parcel && (
          <React.Fragment>
            <Grid container justify="space-between" className={classes.container}>
              <Grid item className={classes.leftContainer}>
                <Typography variant="h5">{parcel.name}</Typography>
                <Typography variant="subtitle2" className={classes.subtitle}>
                  {parcel.farmId.name}
                </Typography>
              </Grid>
              <Grid item className={classes.rightContainer}>
                <Typography variant="h5">{parcel.description}</Typography>
                <Typography variant="subtitle2" className={classes.subtitle}>
                  {parcel.description ? "Descripción" : ""}
                </Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        {!parcel && <Typography variant="h5">Origen</Typography>}
      </StyledActionButton>
      <Modal open={open} onClose={onClose} fullWidth maxWidth={false}>
        <CardGridList>
          {data && orderBy(data.parcels, ["farmId.name", "name"], ["asc", "asc"]).map((parcel) => <FarmCard key={parcel.id} parcel={parcel} onClick={() => onClose(parcel)} />)}
        </CardGridList>
      </Modal>
    </React.Fragment>
  );
});

export default OriginButton;
