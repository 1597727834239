const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const getContrastText = (bgColor) => {
  if (!bgColor) return "#000000";
  const rgb = hexToRgb(bgColor);
  return rgb.r * 0.399 + rgb.g * 0.687 + rgb.b * 0.214 > 186 ? "#000000" : "#ffffff";
  // return rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 > 186 ? "#000000" : "#ffffff";
};

export { getContrastText };
