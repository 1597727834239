import React from "react";
import { orderBy } from "lodash";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { GET_PARTNERS } from "../../apollo/queries";
import Modal from "./Modal";
import PartnerCard from "../cards/PartnerCard";
import CardGridList from "../common/CardGridList";

const PartnerModal = ({ customer, vendor, open, onResult, ...props }) => {
  const variables = { partnerType: (customer && "CUSTOMER") || (vendor && "VENDOR") };
  const { data } = useQuery(GET_PARTNERS, { variables });

  return (
    <Modal open={open} onClose={onResult} fullWidth maxWidth={false} {...props}>
      <CardGridList>
        {data &&
          orderBy(data.partners, [customer ? "saleOrderCount" : "purchaseOrderCount", "name"], ["desc", "asc"]).map((partner) => (
            <PartnerCard key={partner.id} partner={partner} onClick={() => onResult(partner)} />
          ))}
      </CardGridList>
    </Modal>
  );
};

PartnerModal.propTypes = {
  customer: PropTypes.bool,
  vendor: PropTypes.bool,
  open: PropTypes.bool,
  onResult: PropTypes.func,
};

PartnerModal.defaultProps = {
  customer: false,
  vendor: false,
  open: false,
  onResult: () => {},
};

export default PartnerModal;
