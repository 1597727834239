import React from "react";
import { SnackbarProvider as Provider } from "notistack";
import { withStyles } from "@material-ui/core/styles";

const StyledSnackbarProvider = withStyles({
  root: {
    "& .MuiSvgIcon-root": { width: 50, height: 50 },
  },
  message: { fontSize: "1.8vw", margin: 10 },
})(Provider);

const SnackbarProvider = ({ children, ...props }) => {
  return (
    <StyledSnackbarProvider
      autoHideDuration={4100}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    >
      {children}
    </StyledSnackbarProvider>
  );
};

export default SnackbarProvider;
