import withStyles from "@material-ui/core/styles/withStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ProgressBar from "./ProgressBar";
import PropTypes from "prop-types";
import Paper from "./Paper";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  tableHead: {
    marginTop: 100,
  },
  headerRow: {
    paddingTop: 150,
  },
  body: {
    position: "relative",
    height: "80%",
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "calc(50% - 200px)",
    width: 400,
    zIndex: 1000,
  },
  emptyText: {
    position: "absolute",
    top: "45%",
    left: "calc(50% - 170px)",
    opacity: 0.8,
    width: 340,
    zIndex: 1000,
  },
});

const StyledTableContainer = withStyles(() => ({
  root: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}))(TableContainer);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledHeaderTableCell = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}))(TableCell);

const disableStates = ["done", "purchased", "sold"];
const warningStates = ["sale", "purchase"];

const DataTable = ({ columns, rows, height, loading, onRowClick }) => {
  const classes = useStyles();
  const renderHeaderColumns = () =>
    columns.map((column) => (
      <StyledHeaderTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
        <Typography variant="h6">{column.label}</Typography>
      </StyledHeaderTableCell>
    ));
  const renderColumns = (row) =>
    columns.map((column) => {
      const value = row[column.id];
      return (
        <TableCell
          key={column.id}
          align={column.align}
          style={{ backgroundColor: warningStates.includes(row.state) && "#2E7D32", color: warningStates.includes(row.state) && "white" }}
        >
          <Typography variant="body1" style={{ opacity: disableStates.includes(row.state) ? 0.2 : 1 }}>
            {column.format ? column.format(value) : value}
          </Typography>
        </TableCell>
      );
    });

  const renderRows = () =>
    rows.map((row) => (
      <StyledTableRow hover tabIndex={-1} key={row.id} onClick={() => onRowClick && onRowClick(row)}>
        {renderColumns(row)}
      </StyledTableRow>
    ));

  return (
    <Paper className={classes.root}>
      <StyledTableContainer style={{ height }}>
        {loading && (
          <div className={classes.body}>
            <ProgressBar color="primary" className={classes.progress} />
          </div>
        )}
        {!loading && (
          <Table stickyHeader aria-label="sticky table">
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.headerRow}>{renderHeaderColumns()}</TableRow>
            </TableHead>
            <TableBody>{renderRows()}</TableBody>
          </Table>
        )}
        {rows.length <= 0 && (
          <div className={classes.body}>
            <Typography variant="subtitle2" className={classes.emptyText}>
              No existen lineas
            </Typography>
          </div>
        )}
      </StyledTableContainer>
    </Paper>
  );
};

DataTable.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onRowClick: PropTypes.func,
};

DataTable.defaultProps = {
  columns: [],
  rows: [],
};

export default DataTable;
