import React from "react";
import { orderBy } from "lodash";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { StockCard, ProgressBar, CardGridList } from "../../components";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  container: {
    position: "relative",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "calc(50% - 10vw)",
    width: "20vw",
    zIndex: 1,
  },
  overlay: {
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    opacity: 0.35,
  },
}));

const PaletGrid = ({ cols, data, loading, onClick, height, placeholder, enable }) => {
  const classes = useStyles();
  const onCardClick = (data) => {
    if (enable) onClick(data);
  };
  return (
    <div className={classes.container} style={{ height }}>
      {!loading && data && placeholder && (
        <div className={classes.overlay}>
          <Typography variant="h2">{placeholder}</Typography>
        </div>
      )}
      {loading && <ProgressBar color="primary" className={classes.progress} />}
      <CardGridList cols={cols} style={{ padding: 10 }}>
        {!loading &&
          data &&
          orderBy(data, ["locationId.parentName", "locationId.name", "productId.code"], ["asc", "asc", "desc"]).map((data) => (
            <StockCard key={data.id} stock={data} onClick={() => onCardClick(data)} />
          ))}
      </CardGridList>
    </div>
  );
};

export default PaletGrid;
