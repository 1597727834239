import React from "react";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { BackButton, ActionButton, PrinterButton, Paper, ErrorModal } from "../../components";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import PaletPanel from "./PaletPanel";
import StockPanel from "./StockPanel";
import { GET_PALET, GET_STOCK } from "../../apollo/queries";
import { TOGGLE_PALET_STATE } from "../../apollo/mutations";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "80.8vh",
  },
});

const PaletDetailScreen = () => {
  const { id } = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { data: dataStock, loading: loadingStock, refetch: refetchStock } = useQuery(GET_STOCK, { fetchPolicy: "cache-and-network", pollInterval: 30000 });
  const { data: dataPalet, loading: loadingPalet, refetch: refetchPalet } = useQuery(GET_PALET, {
    variables: { id },
    fetchPolicy: "cache-and-network",
    pollInterval: 30000,
  });
  const errorModal = React.useRef();
  const [error, setError] = React.useState("");
  const [togglePaletState] = useMutation(TOGGLE_PALET_STATE, {
    variables: { paletId: id },
    ignoreResults: true,
    onCompleted: () => refetchPalet(),
  });

  const togglePalet = async () => {
    try {
      await togglePaletState();
      enqueueSnackbar(dataPalet && dataPalet.palet.state !== "done" ? "Palet completado correctamente." : "Palet deshecho correctamente.", {
        variant: dataPalet && dataPalet.palet.state !== "done" ? "success" : "info",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        autoHideDuration: 1500,
      });
    } catch (error) {
      setError(error.message.replace(/[(,')]/g, ""));
      errorModal.current.openModal();
    }
  };

  const onDeleteLine = () => {
    refetchPalet();
    refetchStock();
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid container>
          <Grid item sm>
            <BackButton />
          </Grid>
          {dataPalet && dataPalet.palet.state !== "progress" && (
            <Grid item sm style={{ marginLeft: 20 }}>
              <PrinterButton ezpl={dataPalet.palet.id} />
            </Grid>
          )}
          {dataPalet && ["progress", "done"].includes(dataPalet.palet.state) && (
            <Grid item sm style={{ marginLeft: 20 }}>
              {dataPalet.palet.state === "progress" && <ActionButton onClick={togglePalet}>Completado</ActionButton>}
              {dataPalet.palet.state === "done" && (
                <ActionButton action="warning" onClick={togglePalet}>
                  Deshacer
                </ActionButton>
              )}
            </Grid>
          )}
        </Grid>
        <Grid container style={{ marginTop: 20 }}>
          <Grid item sm style={{ marginRight: 20 }}>
            <Paper className={classes.root}>
              <StockPanel
                data={dataStock}
                loading={loadingStock}
                refetch={refetchStock}
                palet={dataPalet && dataPalet.palet}
                onNewPaletLine={() => refetchPalet()}
                enable={dataPalet && dataPalet.palet.state === "progress"}
              />
            </Paper>
          </Grid>
          <Grid item sm>
            <Paper className={classes.root}>
              <PaletPanel palet={dataPalet && dataPalet.palet} loading={loadingPalet} onDeleteLine={onDeleteLine} enable={dataPalet && dataPalet.palet.state === "progress"} />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <ErrorModal ref={errorModal} error={error} />
    </React.Fragment>
  );
};

export default PaletDetailScreen;
