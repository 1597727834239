import React from "react";
import Typography from "@material-ui/core/Typography";
import ActionButton from "../buttons/ActionButton";
import Modal from "./Modal";

const ErrorModal = React.forwardRef(({ error }, ref) => {
  const [open, setOpen] = React.useState(false);

  React.useImperativeHandle(ref, () => ({
    openModal() {
      setOpen(true);
    },
  }));

  return (
    <Modal open={open} onClose={() => setOpen(false)} minWidth={888}>
      <Typography variant="subtitle2" style={{ textAlign: "center" }}>
        <p>{error}</p>
      </Typography>
      <ActionButton action="success" onClick={() => setOpen(false)} style={{ minWidth: 300 }}>
        Aceptar
      </ActionButton>
    </Modal>
  );
});

export default ErrorModal;
