import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { DELETE_PALET_LINE, UPDATE_PALET_PARTNER } from "../../apollo/mutations";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Grid from "@material-ui/core/Grid";
import PaletGrid from "./PaletGrid";
import { useSnackbar } from "notistack";
import { Modal, ActionButton, PartnerModal, StockCard } from "../../components";
import { getContrastText } from "../../utils";
import DestinyPartnerButton from "./DestinyPartnerButton";

const styles = {
  root: {
    width: "100%",
    height: "80.8vh",
  },
  subheader: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: 10,
    fontSize: 25,
  },
  customer: {
    width: "83.5%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  bold: {
    fontWeight: 900,
  },
};

const PaletPanel = ({ palet, onDeleteLine, loading, enable }) => {
  const useStyles = makeStyles((theme) => ({
    ...styles,
    header: {
      padding: 2,
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
    footer: {
      padding: 2,
      borderTop: `1px solid ${theme.palette.grey[400]}`,
    },
    customerText: {
      padding: "0 20px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      backgroundColor: palet && palet.partnerId.color ? palet.partnerId.color : "#0F9F00",
      color: getContrastText(palet.partnerId.color ? palet.partnerId.color : "#0F9F00"),
      fontSize: 25,
    },
  }));

  const [openDelete, setOpenDelete] = React.useState(false);
  const [openPartner, setOpenPartner] = React.useState(false);
  const [updatePaletPartner] = useMutation(UPDATE_PALET_PARTNER, { ignoreResults: true, onCompleted: () => onDeleteLine() });
  const [deleteLine] = useMutation(DELETE_PALET_LINE, { ignoreResults: true, onCompleted: () => onDeleteLine() });
  const [paletLine, setPaletLine] = React.useState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const onClose = () => {
    setOpenDelete(false);
  };

  const onCardClick = (data) => {
    setPaletLine(data);
    setOpenDelete(true);
  };

  const onDelete = async () => {
    try {
      const variables = { paletLineId: paletLine.id };
      setOpenDelete(false);
      await deleteLine({ variables });
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
    }
  };

  const onPartnerResult = async (partner) => {
    setOpenPartner(false);
    if (partner) {
      try {
        const variables = { paletId: palet.id, partnerId: partner.id };
        await updatePaletPartner({ variables });
      } catch (error) {
        enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
        console.log(error);
      }
    }
  };

  return (
    <React.Fragment>
      <div className={classes.header}>
        <Typography variant="subtitle1" className={classes.subheader}>
          {palet && palet.name}
          <ButtonBase onClick={() => setOpenPartner(true)} className={classes.customer}>
            <Typography variant="subtitle1" className={classes.customerText}>
              {(palet && palet.partnerId.name) || "Seleccionar cliente"}
            </Typography>
          </ButtonBase>
        </Typography>
      </div>
      <PaletGrid placeholder={palet.name} cols={1} data={palet.lines} loading={loading} onClick={onCardClick} height="86.8%" enable={enable} />
      <div className={classes.footer}>
        <Typography variant="subtitle1" className={classes.subheader} style={{ padding: 0 }}>
          <DestinyPartnerButton palet={palet} onDestinyPartnerResult={onPartnerResult} />
          <div style={{ paddingRight: 4 }}>
            <span className={classes.bold}>{palet.totalQuantity}</span> CAJAS - (<span className={classes.bold}>{palet.totalKilos}</span> KG)
          </div>
        </Typography>
      </div>
      <Modal open={openDelete} onClose={onClose} minWidth={1000}>
        <StockCard disableRipple stock={paletLine} style={{ marginTop: 10, marginBottom: 25 }} />
        <Grid container>
          <Grid item sm style={{ marginRight: 20 }}>
            <ActionButton action="primary" onClick={() => onClose()} style={{ minWidth: 300 }}>
              Cancelar
            </ActionButton>
          </Grid>
          <Grid item sm>
            <ActionButton action="danger" onClick={() => onDelete()} style={{ minWidth: 300 }}>
              Eliminar
            </ActionButton>
          </Grid>
        </Grid>
      </Modal>
      <PartnerModal customer open={openPartner} onResult={onPartnerResult} />
    </React.Fragment>
  );
};

PaletPanel.propTypes = {
  palet: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onDeleteLine: PropTypes.func,
  loading: PropTypes.bool,
};

PaletPanel.defaultProps = {
  palet: {
    name: "",
    partnerId: {
      name: "",
    },
    totalQuantity: 0,
    totalKilos: 0,
    lines: [],
    state: "",
  },
  onDeleteLine: () => {},
};

export default PaletPanel;
