import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { getContrastText } from "../../utils";
import { DestinyPartnerModal } from "../../components";

const DestinyPartnerButton = ({ palet, onDestinyPartnerResult }) => {
  const useStyles = makeStyles(() => ({
    destinyPartner: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    customerText: {
      padding: "0 20px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      backgroundColor: palet && palet.partnerId.color ? palet.partnerId.color : "#0F9F00",
      color: getContrastText(palet.partnerId.color ? palet.partnerId.color : "#0F9F00"),
      fontSize: 25,
    },
  }));

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isEmpty, setIsEmpty] = React.useState(true);

  const onResult = (partner) => {
    setOpen(false);
    onDestinyPartnerResult(partner);
  };

  const onRowCount = (count) => setIsEmpty(count <= 0);

  return (
    <React.Fragment>
      <div style={{ width: "63.5%" }}>
        {!isEmpty && (
          <ButtonBase onClick={() => setOpen(true)} className={classes.destinyPartner}>
            <Typography variant="subtitle1" className={classes.customerText}>
              {(palet && palet.destinyPartnerId && palet.destinyPartnerId.name) || "Seleccionar destino"}
            </Typography>
          </ButtonBase>
        )}
      </div>
      <DestinyPartnerModal open={open} partnerId={palet && palet.partnerId.id} onResult={onResult} onRowCount={onRowCount} />
    </React.Fragment>
  );
};

DestinyPartnerButton.propTypes = {
  palet: PropTypes.object,
  onDestinyPartnerResult: PropTypes.func,
};

DestinyPartnerButton.defaultProps = {
  onDestinyPartnerResult: () => {},
};

export default DestinyPartnerButton;
