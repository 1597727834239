import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import Modal from "./Modal";
import ActionButton from "../buttons/ActionButton";

const DeleteModal = ({ data, open, onResult, children }) => {
  return (
    <Modal open={open} onClose={onResult} minWidth={1000}>
      {children}
      <Grid container>
        <Grid item sm style={{ marginRight: 20 }}>
          <ActionButton action="primary" onClick={onResult} style={{ minWidth: 300 }}>
            Cancelar
          </ActionButton>
        </Grid>
        <Grid item sm>
          <ActionButton action="danger" onClick={() => onResult(data)} style={{ minWidth: 300 }}>
            Eliminar
          </ActionButton>
        </Grid>
      </Grid>
    </Modal>
  );
};

DeleteModal.propTypes = {
  data: PropTypes.any,
  open: PropTypes.bool,
  onResult: PropTypes.func,
};

DeleteModal.defaultProps = {
  data: false,
  open: false,
  onResult: () => {},
};

export default DeleteModal;
