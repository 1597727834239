import { gql } from "@apollo/client";

const CREATE_RECEPTION = gql`
  mutation CreateReception {
    reception: createReception
  }
`;

const DELETE_RECEPTION = gql`
  mutation DeleteReception($stockPickingId: Int!) {
    deleteReception(stockPickingId: $stockPickingId)
  }
`;

const VALIDATE_RECEPTION = gql`
  mutation ValidateReception($stockPickingId: Int!) {
    validateReception(stockPickingId: $stockPickingId)
  }
`;

const CREATE_LINE_RECEPTION = gql`
  mutation CreateLineReception($stockPickingId: Int!, $stockMoveLine: InputStockMoveLine!) {
    createLineReception(stockPickingId: $stockPickingId, stockMoveLine: $stockMoveLine)
  }
`;

const UPDATE_LINE_RECEPTION = gql`
  mutation UpdateLineReception($stockMoveLineId: Int!, $quantity: Int!) {
    updateLineReception(stockMoveLineId: $stockMoveLineId, quantity: $quantity)
  }
`;

const DELETE_LINE_RECEPTION = gql`
  mutation DeleteLineReception($stockMoveLineId: Int!, $stockPickingId: Int!) {
    deleteLineReception(stockMoveLineId: $stockMoveLineId, stockPickingId: $stockPickingId)
  }
`;

const CREATE_PALET = gql`
  mutation CreatePalet {
    createPalet
  }
`;

const TOGGLE_PALET_STATE = gql`
  mutation TogglePaletState($paletId: Int!) {
    togglePaletState(paletId: $paletId)
  }
`;

const UPDATE_PALET_PARTNER = gql`
  mutation UpdatePaletPartner($paletId: Int!, $partnerId: Int!) {
    updatePaletPartner(paletId: $paletId, partnerId: $partnerId)
  }
`;

const CREATE_PALET_LINE = gql`
  mutation CreatePaletLine($paletId: Int!, $paletLine: InputPaletLine!) {
    createPaletLine(paletId: $paletId, paletLine: $paletLine)
  }
`;

const DELETE_PALET_LINE = gql`
  mutation DeletePaletLine($paletLineId: Int!) {
    deletePaletLine(paletLineId: $paletLineId)
  }
`;

const CREATE_SALE_ORDER = gql`
  mutation CreateSale($partnerId: Int!) {
    id: createSale(partnerId: $partnerId)
  }
`;

const DELETE_SALE_ORDER = gql`
  mutation DeleteSale($id: Int!) {
    deleteSale(id: $id)
  }
`;

const UPDATE_SALE_ORDER_STATE = gql`
  mutation UpdateSaleState($id: Int!, $state: SaleOrderState!) {
    updateSaleState(id: $id, state: $state)
  }
`;

const UPDATE_PARTNER_SALE_ORDER = gql`
  mutation UpdateSalePartner($partnerId: Int!, $saleOrderId: Int!) {
    updateSalePartner(partnerId: $partnerId, saleOrderId: $saleOrderId)
  }
`;

const PALET_SALE_ORDER = gql`
  mutation PaletSale($paletId: Int!, $saleOrderId: Int!, $operation: PaletSaleOperation!) {
    paletSale(paletId: $paletId, saleOrderId: $saleOrderId, operation: $operation)
  }
`;

const CREATE_PURCHASE_ORDER = gql`
  mutation CreatePurchase($partnerId: Int!) {
    id: createPurchase(partnerId: $partnerId)
  }
`;

const DELETE_PURCHASE_ORDER = gql`
  mutation DeletePurchase($id: Int!) {
    deletePurchase(id: $id)
  }
`;

const UPDATE_PURCHASE_ORDER_STATE = gql`
  mutation UpdatePurchaseState($id: Int!, $state: PurchaseOrderState!) {
    updatePurchaseState(id: $id, state: $state)
  }
`;

const UPDATE_PURCHASE_ORDER_PARTNER = gql`
  mutation UpdatePurchasePartner($partnerId: Int!, $purchaseOrderId: Int!) {
    updatePurchasePartner(partnerId: $partnerId, purchaseOrderId: $purchaseOrderId)
  }
`;

const UPDATE_PURCHASE_ORDER_PARTNER_REF = gql`
  mutation UpdatePurchasePartnerRef($partnerRef: String!, $purchaseOrderId: Int!) {
    updatePurchasePartnerRef(partnerRef: $partnerRef, purchaseOrderId: $purchaseOrderId)
  }
`;

const CREATE_PURCHASE_ORDER_LINE = gql`
  mutation CreatePurchaseOrderLine($purchaseOrderId: Int!, $purchaseOrderLine: InputPurchaseOrderLine!) {
    createPurchaseOrderLine(purchaseOrderId: $purchaseOrderId, purchaseOrderLine: $purchaseOrderLine)
  }
`;

const DELETE_PURCHASE_ORDER_LINE = gql`
  mutation DeletePurchaseOrderLine($purchaseOrderLineId: Int!) {
    deletePurchaseOrderLine(purchaseOrderLineId: $purchaseOrderLineId)
  }
`;

const UPDATE_PURCHASE_ORDER_LINE = gql`
  mutation UpdatePurchaseOrderLine($purchaseOrderLineId: Int!, $inputUpdatePurchaseOrderLine: InputUpdatePurchaseOrderLine!) {
    updatePurchaseOrderLine(purchaseOrderLineId: $purchaseOrderLineId, inputUpdatePurchaseOrderLine: $inputUpdatePurchaseOrderLine)
  }
`;

const CREATE_CMR = gql`
  mutation CreateCMR($saleOrderId: Int!, $inputCmr: InputCmr) {
    createCmr(saleOrderId: $saleOrderId, inputCmr: $inputCmr)
  }
`;

const UPDATE_CMR = gql`
  mutation UpdateCMR($id: Int!, $inputCmr: InputCmr!) {
    updateCmr(cmrId: $id, inputCmr: $inputCmr)
  }
`;

const DELETE_CMR = gql`
  mutation DeleteCMR($id: Int!) {
    deleteCmr(cmrId: $id)
  }
`;

const CREATE_CARRIER_COMPANY = gql`
  mutation CreateCarrierCompany($carrierCompany: InputCarrierCompany!) {
    company: createCarrierCompany(carrierCompany: $carrierCompany) {
      carrier {
        id
        name
      }
    }
  }
`;

export {
  CREATE_RECEPTION,
  DELETE_RECEPTION,
  CREATE_LINE_RECEPTION,
  VALIDATE_RECEPTION,
  DELETE_LINE_RECEPTION,
  UPDATE_LINE_RECEPTION,
  CREATE_PALET,
  TOGGLE_PALET_STATE,
  UPDATE_PALET_PARTNER,
  CREATE_PALET_LINE,
  DELETE_PALET_LINE,
  CREATE_SALE_ORDER,
  DELETE_SALE_ORDER,
  UPDATE_SALE_ORDER_STATE,
  UPDATE_PARTNER_SALE_ORDER,
  PALET_SALE_ORDER,
  CREATE_PURCHASE_ORDER,
  DELETE_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER_STATE,
  UPDATE_PURCHASE_ORDER_PARTNER,
  UPDATE_PURCHASE_ORDER_PARTNER_REF,
  CREATE_PURCHASE_ORDER_LINE,
  DELETE_PURCHASE_ORDER_LINE,
  UPDATE_PURCHASE_ORDER_LINE,
  CREATE_CMR,
  UPDATE_CMR,
  DELETE_CMR,
  CREATE_CARRIER_COMPANY,
};
