import React from "react";
import ReactDOM from "react-dom";
import "fontsource-roboto/700-normal.css";
import "fontsource-roboto/500-normal.css";
import "fontsource-roboto/400-normal.css";
import "fontsource-roboto/300-normal.css";
import "./index.css";
import App from "./App";

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById("root")
);
