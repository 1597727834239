import { createMuiTheme } from "@material-ui/core/styles";
import cyan from "@material-ui/core/colors/cyan";
import deepPurple from "@material-ui/core/colors/deepPurple";
import purple from "@material-ui/core/colors/purple";
import amber from "@material-ui/core/colors/amber";
import pink from "@material-ui/core/colors/pink";

/*
Thin: 100
Light: 300
Regular: 400
Medium: 500
Bold: 700
Black: 900
*/

const theme = createMuiTheme({
  palette: {
    terciary: {
      main: deepPurple[500],
      dark: deepPurple[800],
    },
    warning: {
      main: amber[400],
      dark: amber[600],
    },
    reception: {
      main: amber[500],
      dark: amber[700],
    },
    purchase: {
      main: pink[500],
      dark: pink[700],
    },
    palet: {
      main: purple[500],
      dark: purple[700],
    },
    sale: {
      main: cyan[700],
      dark: cyan[800],
    },
  },
  typography: {
    fontSize: 30,
    button: {
      fontSize: 40,
      fontWeight: 700,
      textTransform: "uppercase",
    },
    h2: {
      fontSize: 70,
      fontWeight: 500,
      lineHeight: 1.3,
      letterSpacing: "0.028em",
      textTransform: "uppercase",
    },
    h3: {
      fontSize: 60,
      fontWeight: 400,
      lineHeight: 1.3,
      letterSpacing: "0.028em",
      textTransform: "uppercase",
    },
    h4: {
      fontSize: 53,
      fontWeight: 500,
      lineHeight: 1.734,
      letterSpacing: "0.028em",
      textTransform: "uppercase",
    },
    h5: {
      fontSize: 40,
      fontWeight: 700,
      lineHeight: 1.734,
      letterSpacing: "0.028em",
      textTransform: "uppercase",
    },
    h6: {
      fontSize: 30,
      fontWeight: 500,
      textTransform: "uppercase",
    },
    body1: {
      fontSize: 30,
      fontWeight: 400,
      lineHeight: 1.4,
      textTransform: "uppercase",
    },
    caption: {
      fontSize: 42,
      fontWeight: 400,
      textTransform: "uppercase",
    },
    body2: {
      fontSize: 30,
      fontWeight: 400,
      textTransform: "uppercase",
    },
    subtitle1: {
      fontSize: 23,
      fontWeight: 400,
      textTransform: "uppercase",
    },
    subtitle2: {
      fontSize: 36,
      fontWeight: 500,
      textTransform: "uppercase",
    },
    overline: {
      fontSize: 42,
      fontWeight: 500,
      textTransform: "uppercase",
      letterSpacing: "0.03333em",
      lineHeight: 1.66,
    },
  },
  shape: {
    borderRadius: 0,
  },
  margin: (index) => {
    const margin = [20];
    return margin[index];
  },
});

export default theme;
