import React from "react";
import { Modal, DataTable, ActionButton, Keypad, ProductCard } from "../../components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import { UPDATE_LINE_RECEPTION, DELETE_LINE_RECEPTION } from "../../apollo/mutations";

const UpdateLineDataTable = ({ stockPicking, onLineUpdate, ...props }) => {
  const [openKeypad, setOpenKeypad] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [row, setRow] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [deleteLineReception] = useMutation(DELETE_LINE_RECEPTION, { ignoreResults: true, onCompleted: () => onLineUpdate() });
  const [updateLineReception] = useMutation(UPDATE_LINE_RECEPTION, { ignoreResults: true, onCompleted: () => onLineUpdate() });

  const showDialog = (row) => {
    setRow(row);
    setOpen(true);
  };
  const onClose = (value) => {
    if (value === "delete") {
      deleteLine();
    } else if (value === "edit") {
      setOpenKeypad(true);
    }
    setOpen(false);
  };

  const deleteLine = async () => {
    try {
      const variables = { stockPickingId: stockPicking.id, stockMoveLineId: row.id };
      await deleteLineReception({ variables });
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
    }
  };

  const updateLine = async (quantity) => {
    try {
      const variables = { stockMoveLineId: row.id, quantity };
      await updateLineReception({ variables });
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
    }
  };

  const onAccept = (quantity) => {
    setOpenKeypad(false);
    updateLine(quantity);
  };

  return (
    <React.Fragment>
      <DataTable {...props} onRowClick={showDialog} />
      <Modal open={open} onClose={onClose} minWidth={1000}>
        <Typography variant="subtitle2" style={{ textAlign: "center" }}>
          <p>
            {row && row.productId.code}
            <br />
            {row && row.locationId.completeName}
          </p>
        </Typography>
        <Grid container spacing={2}>
          {stockPicking && stockPicking.state !== "done" && (
            <Grid item sm>
              <ActionButton action="danger" onClick={() => onClose("delete")} style={{ minWidth: 300 }}>
                Eliminar
              </ActionButton>
            </Grid>
          )}
          <Grid item sm>
            <ActionButton action="primary" onClick={() => onClose("edit")} style={{ minWidth: 300 }}>
              Editar
            </ActionButton>
          </Grid>
        </Grid>
      </Modal>
      <Modal open={openKeypad} onClose={() => setOpenKeypad(false)} minWidth={888}>
        <Keypad defaultValue={row.quantity} onAccept={onAccept} uom={row.uom}>
          <ProductCard disableRipple product={row && row.productId} />
        </Keypad>
      </Modal>
    </React.Fragment>
  );
};

export default UpdateLineDataTable;
