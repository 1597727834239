import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Modal, Keypad, Keyboard, CarrierModal } from "../../components";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import { UPDATE_CMR } from "../../apollo/mutations";

const useStyles = makeStyles({
  wrapText: {
    textAlign: "start",
    fontSize: 27,
    lineHeight: 1,
    minHeight: 29,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

const CmrField = ({ id, field, dataField, value, uom, onUpdate, ...props }) => {
  const classes = useStyles();
  const [openKeypad, setOpenKeypad] = React.useState(false);
  const [openKeyboard, setOpenKeyboard] = React.useState(false);
  const [openCarrierModal, setOpenCarrierModal] = React.useState(false);
  const [updateField] = useMutation(UPDATE_CMR, { ignoreResults: true, onCompleted: () => onUpdate() });

  const { enqueueSnackbar } = useSnackbar();

  const onKeypadResult = (result) => {
    setOpenKeypad(false);
    updateCMR(result);
  };

  const onKeyboardResult = (result) => {
    setOpenKeyboard(false);
    if (result) updateCMR(result);
  };

  const onCarrierResult = (carrier) => {
    setOpenCarrierModal(false);
    if (carrier) updateCMR(carrier.id);
  };

  const updateCMR = async (value) => {
    try {
      const variables = { id, inputCmr: { [field]: value } };
      await updateField({ variables });
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
    }
  };

  const openModal = () => {
    if (dataField.type === "str") setOpenKeyboard(true);
    else if (dataField.type === "int") setOpenKeypad(true);
    else setOpenCarrierModal(true);
  };

  return (
    <React.Fragment>
      <ButtonBase onClick={openModal} {...props} style={{ display: "block", padding: 10, width: "100%" }}>
        <Typography variant="subtitle1" style={{ textAlign: "start", opacity: 0.6 }}>
          {dataField && dataField.label}
        </Typography>
        <Typography variant="subtitle1" className={classes.wrapText}>
          {value !== null && typeof value === "object" ? value.name : value} {uom}
        </Typography>
      </ButtonBase>
      <Modal open={openKeypad} onClose={() => setOpenKeypad(false)} minWidth={888}>
        <Keypad onAccept={onKeypadResult} uom={uom ? uom : "Unidades"} defaultValue={value !== null && typeof value === "object" ? value.name : value}>
          <Typography variant="subtitle2" style={{ marginBottom: 15 }}>
            {dataField && dataField.label}
          </Typography>
        </Keypad>
      </Modal>
      <Modal open={openKeyboard} onClose={() => setOpenKeyboard(false)} minWidth={1580}>
        <Keyboard onResult={onKeyboardResult} defaultValue={value !== null && typeof value === "object" ? value.name : value}>
          <Typography variant="subtitle2" style={{ marginBottom: 15 }}>
            {dataField && dataField.label}
          </Typography>
        </Keyboard>
      </Modal>
      <CarrierModal open={openCarrierModal} onResult={onCarrierResult} onClose={() => setOpenCarrierModal(false)} />
    </React.Fragment>
  );
};

export default CmrField;
