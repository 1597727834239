import React from "react";
import Grid from "@material-ui/core/Grid";
import { BackButton, OriginButton, SecureButton, Paper } from "../../components";
import UpdateLineDataTable from "./UpdateLineDataTable";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_RECEPTION } from "../../apollo/queries";
import { DELETE_RECEPTION, VALIDATE_RECEPTION } from "../../apollo/mutations";
import NewLineButton from "./NewLineButton";

const useStyles = makeStyles((theme) => ({
  centerButton: {
    marginRight: theme.margin(0),
    marginLeft: theme.margin(0),
  },
  button: {
    marginLeft: theme.margin(0),
  },
}));

const columns = [
  { id: "productId", label: "PRODUCTO", minWidth: 170, format: (value) => value.code },
  { id: "locationId", label: "DESDE", minWidth: 100, format: (value) => value.completeName },
  {
    id: "lot",
    label: "LOTE",
    minWidth: 170,
  },
  {
    id: "quantity",
    label: "CANTIDAD",
    minWidth: 170,
    align: "right",
  },
  {
    id: "uom",
    label: "UNIDAD",
    minWidth: 170,
    align: "left",
  },
];

const ReceptionDetailScreen = ({ location }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [parcel, setParcel] = React.useState(false);
  const { data, refetch, loading } = useQuery(GET_RECEPTION, {
    variables: { id },
    fetchPolicy: "cache-and-network",
    pollInterval: 30000,
  });
  const [deleteReception] = useMutation(DELETE_RECEPTION, {
    variables: { stockPickingId: id },
    onCompleted: (result) => {
      if (result) backButtonRef.current.goBack();
    },
  });

  const [validateReception] = useMutation(VALIDATE_RECEPTION, {
    variables: { stockPickingId: id },
    ignoreResults: true,
    onCompleted: () => refetchLines(),
  });

  const backButtonRef = React.useRef();
  const newLineRef = React.useRef();
  const originRef = React.useCallback(
    (node) => {
      if (node && location.state && location.state.create) {
        node.showDialog();
        location.state = undefined;
      }
    },
    [location]
  );

  const onDeleteReception = async () => {
    try {
      await deleteReception();
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
    }
  };

  const refetchLines = () => refetch();

  const onConfirmReception = async () => {
    try {
      await validateReception();
      enqueueSnackbar("Recepción confirmada correctamente.", {
        variant: "success",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        autoHideDuration: 1500,
      });
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
    }
  };

  const onOriginChange = (value) => {
    setParcel(value);
    newLineRef.current.showDialog();
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid container>
          <Grid item sm>
            <BackButton ref={backButtonRef} />
          </Grid>
          {data && data.reception.state !== "done" && (
            <Grid item sm className={classes.centerButton}>
              <OriginButton onChange={onOriginChange} ref={originRef} />
            </Grid>
          )}
          {data && data.reception.state !== "done" && (
            <Grid item sm>
              <NewLineButton disabled={!parcel} onNewLine={refetchLines} parcel={parcel} ref={newLineRef} />
            </Grid>
          )}
        </Grid>
        <Grid container item style={{ marginTop: 20 }}>
          <Grid item sm>
            <Paper>
              <Typography variant="h6" style={{ minHeight: 50, padding: 12 }}>
                {data && data.reception.name}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 20 }}>
          <Grid item sm>
            <UpdateLineDataTable
              stockPicking={data && data.reception}
              onLineUpdate={refetchLines}
              loading={loading}
              columns={columns}
              rows={data && data.reception.lines}
              height={data && data.reception.state !== "done" ? "55.5vh" : "70vh"}
            />
          </Grid>
        </Grid>
        {data && data.reception.state !== "done" && (
          <Grid container item style={{ marginTop: 20 }}>
            <Grid item sm>
              <SecureButton
                acceptButton={{ name: "Eliminar", action: "danger" }}
                cancelButton={{ name: "Cancelar", action: "primary" }}
                message={`¿Seguro que desea eliminar la entrada '${data && data.reception.name}'?`}
                onResult={onDeleteReception}
              >
                Eliminar
              </SecureButton>
            </Grid>
            <Grid item sm className={classes.button}>
              <SecureButton
                acceptButton={{ name: "Aceptar", action: "primary" }}
                cancelButton={{ name: "Cancelar", action: "danger" }}
                message={`¿Seguro que desea marca la entrada '${data && data.reception.name}' como recibida?`}
                disabled={data && data.reception.lines.length < 1}
                action="primary"
                onResult={() => onConfirmReception()}
              >
                Confirmar
              </SecureButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default ReceptionDetailScreen;
