import { gql } from "@apollo/client";

/**  RECEPTION QUERIES  **/

const GET_RECEPTIONS = gql`
  query Receptions {
    receptions {
      id
      name
      datetime
      state
    }
  }
`;

const GET_RECEPTION = gql`
  query Reception($id: Int!) {
    reception(id: $id) {
      id
      name
      datetime
      state
      lines {
        id
        productId {
          id
          name
          displayName
          code
          image
          color
        }
        lot
        locationId {
          id
          completeName
        }
        quantity
        uom
      }
    }
  }
`;

const GET_PARCELS = gql`
  query Parcels {
    parcels {
      id
      name
      color
      description
      farmId {
        id
        name
      }
    }
  }
`;

const GET_CULTIVATE_PRODUCTS = gql`
  query CultivateProducts {
    products: cultivateProducts {
      id
      displayName
      priority
      name
      code
      color
      image
      uom
    }
  }
`;

/**  PALET QUERIES **/

const GET_PALETS = gql`
  query Palets {
    palets {
      id
      name
      code
      partnerId {
        id
        name
        color
      }
      orderId {
        id
        name
      }
      totalQuantity
      totalKilos
      state
    }
  }
`;

const GET_PALET = gql`
  query Palet($id: Int!) {
    palet(id: $id) {
      id
      name
      code
      partnerId {
        id
        name
        color
      }
      destinyPartnerId {
        id
        name
        color
      }
      totalQuantity
      totalKilos
      lines {
        id
        productId {
          id
          code
        }
        locationId {
          id
          name
          parentName
          parcelId {
            color
          }
        }
        quantity
        kilos
        uom
      }
      state
    }
  }
`;

const GET_PALETS_BY_STATES = gql`
  query Palets($state: PaletState) {
    palets(state: $state) {
      id
      name
      code
      partnerId {
        id
        name
        color
      }
      totalQuantity
      totalKilos
      state
    }
  }
`;

/* STOCK QUERIES */
const GET_STOCK = gql`
  query Stock {
    stock {
      id
      productId {
        id
        code
        produceProducts {
          id
          name
          code
          color
          image
          priority
        }
      }
      locationId {
        id
        name
        parentName
        parcelId {
          id
          color
        }
      }
      quantity
      uom
    }
  }
`;

/* PARTNER QUERIES */
const GET_PARTNERS = gql`
  query Partners($partnerType: PartnerType!) {
    partners(partnerType: $partnerType) {
      id
      name
      city
      country
      image
      color
      saleOrderCount
      purchaseOrderCount
    }
  }
`;

const GET_DESTINY_PARTNERS = gql`
  query DestinyPartners($partnerId: Int!) {
    destinyPartners(partnerId: $partnerId) {
      id
      name
      city
      country
      image
      color
      parentId {
        color
      }
    }
  }
`;

/* SALES QUERIES */
const GET_SALES = gql`
  query Sales {
    sales {
      id
      name
      partnerId {
        id
        name
      }
      dateOrder
      state
    }
  }
`;

const GET_SALE = gql`
  query Sale($id: Int!) {
    sale(id: $id) {
      id
      name
      state
      partnerId {
        id
        name
        color
      }
      orderLines {
        id
        productId {
          id
          code
        }
        quantity
        kilos
        priceUnit
      }
      cmrId {
        id
      }
      palets {
        id
        name
        code
        partnerId {
          id
          name
          color
        }
        totalQuantity
        totalKilos
        state
      }
    }
  }
`;

/* PURCHASE QUERIES */
const GET_PURCHASES = gql`
  query Purchases {
    purchases {
      id
      name
      partnerRef
      partnerId {
        id
        name
      }
      dateOrder
      state
    }
  }
`;

const GET_PURCHASE = gql`
  query Purchase($id: Int!) {
    purchase(id: $id) {
      id
      state
      dateOrder
      partnerRef
      partnerId {
        id
        name
      }
      orderLines {
        id
        productId {
          id
          name
          displayName
          code
          image
          tracking
          color
        }
        quantity
        uom
        priceUnit
        lot
      }
    }
  }
`;

const GET_PACKAGING_PRODUCTS = gql`
  query PackagingProducts {
    products: packagingProducts {
      id
      displayName
      name
      code
      color
      image
      tracking
      priority
    }
  }
`;

const GET_CMR = gql`
  query CMR($id: Int!) {
    cmr(id: $id) {
      id
      name
      partnerId {
        id
        name
      }
      date
      state
      transportDni
      carrierCompanyId {
        id
        name
      }
      trailerPlate
      tractorPlate
      temperature
      destinyStreet
      destinyCountry
      paletCount
      paletCountReceived
      cmrLines {
        id
        name
        quantity
        ware
        packaging
        size
      }
    }
  }
`;

const GET_CARRIER_COMPANIES = gql`
  query CarrierCompanies {
    companies: carrierCompanies {
      id
      name
    }
  }
`;

const IS_LOGGED = gql`
  query IsLogged {
    isLogged
  }
`;

export {
  GET_RECEPTIONS,
  GET_RECEPTION,
  GET_PARCELS,
  GET_CULTIVATE_PRODUCTS,
  GET_PALETS,
  GET_PALETS_BY_STATES,
  GET_PALET,
  GET_STOCK,
  GET_PARTNERS,
  GET_DESTINY_PARTNERS,
  GET_SALES,
  GET_SALE,
  GET_PURCHASES,
  GET_PURCHASE,
  GET_PACKAGING_PRODUCTS,
  GET_CMR,
  GET_CARRIER_COMPANIES,
  IS_LOGGED,
};
