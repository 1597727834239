import React from "react";
import { useSnackbar } from "notistack";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_PALETS_BY_STATES } from "../../apollo/queries";
import { PALET_SALE_ORDER } from "../../apollo/mutations";
import { ActionButton, PaletModal, ErrorModal } from "../../components";

const LinkPaletSaleButton = React.forwardRef(({ saleOrderId, onLinkedPalet, ...props }, ref) => {
  const errorModal = React.useRef();
  const [getPalets, { data }] = useLazyQuery(GET_PALETS_BY_STATES, { variables: { state: "DONE" }, fetchPolicy: "no-cache", onCompleted: () => openModal() });
  const [paletSaleOrder] = useMutation(PALET_SALE_ORDER, { ignoreResults: true, onCompleted: () => onLinkedPalet() });
  const [openPalet, setOpenPalet] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  React.useImperativeHandle(ref, () => ({
    openPaletDialog() {
      getPalets();
    },
  }));

  const linkPaletSale = async (palet) => {
    try {
      const variables = { paletId: palet, saleOrderId: parseInt(saleOrderId), operation: "LINK" };
      await paletSaleOrder({ variables });
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
    }
  };

  const openModal = async () => {
    if (data && data.palets.length > 0) {
      setOpenPalet(true);
    } else {
      errorModal.current.openModal();
    }
  };

  const onPaletResult = (palet) => {
    setOpenPalet(false);
    if (palet) linkPaletSale(palet.id);
  };

  return (
    <React.Fragment>
      <ActionButton action="success" onClick={() => getPalets()} {...props}>
        Añadir palet
      </ActionButton>
      <PaletModal data={data} open={openPalet} onResult={onPaletResult} />
      <ErrorModal ref={errorModal} error="¡No quedan palets pendientes de vender!" />
    </React.Fragment>
  );
});

export default LinkPaletSaleButton;
