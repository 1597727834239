const FIELDS = [
  [
    {
      carrierCompanyId: {
        label: "Empresa de transporte",
        type: "carrier",
        col: 3,
      },
      transportDni: {
        label: "DNI del transportista",
        type: "str",
        col: 3,
      },
      trailerPlate: {
        label: "Semirremolque",
        type: "str",
        col: 3,
      },
      tractorPlate: {
        field: "",
        label: "Tractora",
        type: "str",
        col: 3,
      },
    },
  ],
  [
    {
      temperature: {
        label: "Temperatura",
        type: "int",
        uom: "ºC",
        col: 2,
      },
      paletCount: {
        label: "Europ. cargados",
        type: "int",
        uom: "palets",
        col: 2,
      },
      paletCountReceived: {
        label: "Europ. recibidos",
        type: "int",
        uom: "palets",
        col: 2,
      },
      destinyStreet: {
        label: "Dirección de destino",
        type: "str",
        col: 3,
      },
      destinyCountry: {
        label: "País de destino",
        type: "str",
        col: 3,
      },
    },
  ],
];

export { FIELDS };
