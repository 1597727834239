import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { getContrastText } from "../../utils";

const styles = {
  subtitle: {
    position: "relative",
    top: -13,
    opacity: 0.67,
  },
  avatarContainer: {
    marginLeft: -8,
  },
  bodyContainer: {
    marginLeft: 10,
    textAlign: "left",
  },
};

const ProductCard = ({ showAvatar, product, ...props }) => {
  const color = getContrastText(product.color);
  const useStyles = makeStyles((theme) => ({
    ...styles,
    button: {
      width: "100%",
      minWidth: 680,
      height: 131,
      backgroundColor: product.color,
      "&:hover": {
        backgroundColor: product.color,
      },
      color,
    },
    avatar: {
      height: 115,
      width: 115,
      border: `1px solid ${color}`,
      backgroundColor: "transparent",
      fontSize: 90,
      color,
    },
  }));
  const classes = useStyles();
  return (
    <Button variant="outlined" disableElevation className={classes.button} {...props}>
      {showAvatar && (
        <div className={classes.avatarContainer}>
          <Avatar variant="square" alt="Product" src={product.image ? `data:image/png;base64, ${product.image}` : undefined} className={classes.avatar}>
            {product.name && product.name.charAt(0)}
          </Avatar>
        </div>
      )}
      <Grid container direction="column" className={classes.bodyContainer}>
        <Grid item>
          <Typography variant="caption">{product.code}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" className={classes.subtitle}>
            {product.name}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );
};

ProductCard.propTypes = {
  showAvatar: PropTypes.bool,
  product: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onClick: PropTypes.func,
};

ProductCard.defaultProps = {
  showAvatar: true,
  product: {},
  onClick: () => {},
};

export default ProductCard;
