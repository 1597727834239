import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import ActionButton from "./ActionButton";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { PDFDocument } from "pdf-lib";
import url from "../../utils/websocket";

const PrinterButton = ({ ezpl, pdf, pdfs }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const { sendMessage, readyState } = useWebSocket(url, {
    shouldReconnect: () => true,
    reconnectInterval: 10000,
    share: true,
  });

  const sendToPrinter = async () => {
    setLoading(true);
    if (ezpl) {
      await printEZPL(ezpl);
    } else if (pdf) {
      sendMessage(await requestPDF(pdf));
    } else if (pdfs) {
      await printPDFs(pdfs);
    }
    setLoading(false);
    enqueueSnackbar("Imprimiendo...", {
      variant: "info",
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
      autoHideDuration: ezpl ? 1300 : 4000,
    });
  };

  const printEZPL = async (ezpl) => {
    const response = await axios.get(`/report/text/agro_mrp.label_palet_ezpl/${ezpl}`, {
      headers: { "Content-Type": "text/plain; charset=utf-8" },
    });
    sendMessage(response.data);
  };

  const requestPDF = async (pdf) => {
    const response = await axios.get(`/report/pdf/${pdf}`, {
      headers: { "Content-Type": "application/pdf" },
      responseType: "arraybuffer",
    });
    return response.data;
  };

  const printPDFs = async (pdfs) => {
    let pdfsToMerge = [];
    for (const pdf of pdfs) {
      pdfsToMerge.push(await requestPDF(pdf));
    }
    const mergedPdf = await PDFDocument.create();
    for (const pdfBytes of pdfsToMerge) {
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const copies = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
      copies.forEach((page) => mergedPdf.addPage(page));
    }
    sendMessage(await mergedPdf.save());
  };

  return (
    <ActionButton loading={loading} action="info" disabled={readyState !== ReadyState.OPEN} onClick={() => sendToPrinter()}>
      Imprimir
    </ActionButton>
  );
};

PrinterButton.propTypes = {
  ezpl: PropTypes.number,
  pdf: PropTypes.string,
  pdfs: PropTypes.array,
};

PrinterButton.defaultProps = {
  pdfs: [],
};

export default PrinterButton;
