import React from "react";
import Grid from "@material-ui/core/Grid";
import { BackButton, ActionButton, SecureButton, Paper, PartnerModal } from "../../components";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useSnackbar } from "notistack";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_PURCHASE } from "../../apollo/queries";
import { UPDATE_PURCHASE_ORDER_PARTNER, UPDATE_PURCHASE_ORDER_STATE, DELETE_PURCHASE_ORDER } from "../../apollo/mutations";
import { formatDate, formatMoney, formatQuantity } from "../../utils";
import NewLineButton from "./NewLineButton";
import UpdateLineDataTable from "./UpdateLineDataTable";
import UpdatePartnerRef from "./UpdatePartnerRef";

const useStyles = makeStyles((theme) => ({
  centerButton: {
    marginRight: theme.margin(0),
    marginLeft: theme.margin(0),
  },
  rightButton: {
    marginLeft: theme.margin(0),
  },
  button: {
    marginLeft: theme.margin(0),
  },
  root: {
    width: "100%",
    height: 537,
  },
  rootComplete: {
    height: 667,
  },
  container: {
    overflowY: "auto",
    height: "100%",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  subheader: {
    display: "flex",
    justifyContent: "space-between",
  },
  leftHeader: {
    borderRight: `1px solid ${theme.palette.grey[400]}`,
    minHeight: 50,
    padding: 12,
    textAlign: "center",
  },
  centerHeader: {
    minHeight: 50,
    padding: 12,
    textAlign: "center",
  },
  buttonCenterHeader: {
    borderRight: `1px solid ${theme.palette.grey[400]}`,
    height: "100%",
    width: "100%",
  },
}));

const columns = [
  { id: "productId", label: "PRODUCTO", minWidth: 170, format: (value) => value.displayName },
  { id: "quantity", label: "CANTIDAD", minWidth: 100, align: "center", format: (value) => formatQuantity(value) },
  { id: "lot", label: "LOTE", minWidth: 170, align: "center" },
  { id: "priceUnit", label: "PRECIO", minWidth: 170, align: "center", format: (value) => formatMoney(value) },
];

const PurchaseDetailScreen = ({ location }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { data, refetch, loading } = useQuery(GET_PURCHASE, {
    variables: { id },
    fetchPolicy: "cache-and-network",
    pollInterval: 30000,
  });

  const onCompleted = () => refetch();
  const [openPartner, setOpenPartner] = React.useState(false);

  const [updatePurchaseOrderState] = useMutation(UPDATE_PURCHASE_ORDER_STATE, { ignoreResults: true, onCompleted });
  const [updatePurchaseOrderPartner] = useMutation(UPDATE_PURCHASE_ORDER_PARTNER, { ignoreResults: true, onCompleted });
  const [deletePurchaseOrder] = useMutation(DELETE_PURCHASE_ORDER, {
    onCompleted: (result) => {
      if (result) backButtonRef.current.goBack();
    },
  });

  const backButtonRef = React.useRef();
  const newLineRef = React.useCallback(
    (node) => {
      if (node && location.state && location.state.create) {
        node.showDialog();
        location.state = undefined;
      }
    },
    [location]
  );

  const onDeletePurchase = async () => {
    try {
      const variables = { id };
      await deletePurchaseOrder({ variables });
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
    }
  };

  const updateState = async (state) => {
    try {
      const variables = { id, state };
      await updatePurchaseOrderState({ variables });
      let actions = {
        CONFIRM: { message: "Compra confirmada correctamente.", variant: "success" },
        REVERSE: { message: "Compra convertida a borrador correctamente.", variant: "info" },
        DONE: { message: "Compra recibida correctamente.", variant: "success" },
      };
      enqueueSnackbar(actions[state].message, {
        variant: actions[state].variant,
        anchorOrigin: { vertical: state === "DONE" ? "bottom" : "top", horizontal: "center" },
        autoHideDuration: 1500,
      });
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
    }
  };

  const updatePartner = async (partner) => {
    try {
      const variables = { partnerId: partner, purchaseOrderId: parseInt(id) };
      await updatePurchaseOrderPartner({ variables });
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
    }
  };

  const onPartnerResult = (partner) => {
    setOpenPartner(false);
    if (partner) updatePartner(partner.id);
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid container>
          <Grid item sm>
            <BackButton ref={backButtonRef} />
          </Grid>
          {data && data.purchase.state === "draft" && (
            <Grid item sm style={{ marginLeft: 20 }}>
              <NewLineButton onNewLine={() => refetch()} ref={newLineRef}>
                Agregar linea
              </NewLineButton>
            </Grid>
          )}
        </Grid>
        <Grid container item style={{ marginTop: 20 }}>
          <Grid item sm>
            <Paper>
              <Grid container>
                <Grid item sm={3}>
                  <UpdatePartnerRef onUpdatePartnerRef={() => refetch()}>{data && data.purchase.partnerRef}</UpdatePartnerRef>
                </Grid>
                <Grid item sm={6}>
                  <ButtonBase
                    onClick={() => {
                      if (data && data.purchase.state === "draft") setOpenPartner(true);
                    }}
                    className={classes.buttonCenterHeader}
                  >
                    <Typography variant="h6" className={classes.centerHeader}>
                      {data && data.purchase.partnerId.name}
                    </Typography>
                  </ButtonBase>
                </Grid>
                <Grid item sm={3}>
                  <Typography variant="h6" className={classes.leftHeader}>
                    {data && formatDate(new Date(data.purchase.dateOrder))}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 20 }}>
          <Grid item sm>
            <UpdateLineDataTable
              enabled={data && data.purchase.state === "draft"}
              purchaseOrder={data && data.purchase}
              onLineUpdate={() => refetch()}
              loading={loading}
              columns={columns}
              rows={data && data.purchase.orderLines}
              height={data && data.purchase.state === "purchased" ? "70vh" : "55.5vh"}
            />
          </Grid>
        </Grid>

        {data && data.purchase.state !== "purchased" && (
          <Grid container item style={{ marginTop: 20 }}>
            <Grid item sm>
              {data && data.purchase.state === "draft" ? (
                <SecureButton
                  acceptButton={{ name: "Eliminar", action: "danger" }}
                  cancelButton={{ name: "Cancelar", action: "primary" }}
                  message={`¿Seguro que desea eliminar la compra '${data && data.purchase.name}'?`}
                  onResult={onDeletePurchase}
                >
                  Eliminar
                </SecureButton>
              ) : (
                <ActionButton action="danger" onClick={() => updateState("REVERSE")}>
                  Borrador
                </ActionButton>
              )}
            </Grid>
            <Grid item sm className={classes.button}>
              {data && data.purchase.state === "draft" ? (
                <ActionButton action="primary" onClick={() => updateState("CONFIRM")}>
                  Confirmar
                </ActionButton>
              ) : (
                <SecureButton
                  acceptButton={{ name: "Aceptar", action: "primary" }}
                  cancelButton={{ name: "Cancelar", action: "danger" }}
                  message={`¿Seguro que desea marca la compra '${data && data.purchase.name}' como recibida?`}
                  action="primary"
                  onResult={() => updateState("DONE")}
                >
                  Recibido
                </SecureButton>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      <PartnerModal vendor open={openPartner} onResult={onPartnerResult} />
    </React.Fragment>
  );
};

export default PurchaseDetailScreen;
