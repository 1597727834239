import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const Modal = ({ open, onClose, minWidth, children, ...props }) => {
  const useStyles = makeStyles(() => ({
    root: {
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    paper: { minWidth }, //TODO CALCULATE (CARD WIDTH * NUMBERS COLUMNS) + PIXEL MARGINS 1190
  }));

  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paper: classes.paper }} {...props}>
      <DialogContent dividers classes={{ root: classes.root }}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

Modal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  minWidth: PropTypes.number,
  children: PropTypes.node,
};

Modal.defaultProps = {
  open: false,
  onClose: () => {},
  minWidth: 500,
};

export default Modal;
