import React from "react";
import axios from "axios";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import { useSnackbar } from "notistack";
import { ActionButton, TextField } from "../../components";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles({
  formContainer: {
    width: "90%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  bodyContainer: {
    textAlign: "left",
    height: "100%",
  },
  avatar: {
    height: 300,
    width: 300,
    backgroundColor: "transparent",
  },
  version: {
    position: "absolute",
    right: 0,
    top: 0,
    opacity: 0.5,
    textTransform: "lowercase",
  },
});

const LoginScreen = () => {
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = React.useState({ login: "", password: "", db: localStorage.getItem("db") });
  const [formState, setFormState] = React.useState({ helperText: "", error: false });
  const [loading, setLoading] = React.useState(false);

  const login = async (event) => {
    if (event) event.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post("/web/session/authenticate", { params: { ...formValues } }, { headers: { "Content-Type": "application/json" }, timeout: 8000 });
      setLoading(false);
      if (!response.data.error) successLogin();
      else incorrectLogin();
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
      setLoading(false);
    }
  };

  const successLogin = () => {
    if (localStorage.getItem("db") !== formValues.db) localStorage.setItem("db", formValues.db);
    history.push("/menu");
  };

  const incorrectLogin = () => {
    setFormValues({ ...formValues, password: "" });
    setFormState({ helperText: "¡Usuario/Contraseña incorrecta!", error: true });
  };

  const onTextFieldChange = (field, event) => {
    let vals = { ...formValues };
    vals[field] = event.target.value;
    if (formState.error) setFormState({ helperText: "", error: false });
    setFormValues(vals);
  };

  return (
    <React.Fragment>
      <form noValidate className={classes.formContainer} onSubmit={login}>
        <Grid container alignItems="center">
          <Grid item sm={5} style={{ textAlign: "right", paddingRight: 20 }}>
            <img src="/static/logo.svg" alt="" className={classes.avatar} />
          </Grid>
          <Grid container item sm={7} justify="center" direction="column" className={classes.bodyContainer}>
            <Grid item style={{ marginBottom: -12 }}>
              <Typography variant="h2">LAS CARABELAS</Typography>
            </Grid>
            <Grid item style={{ marginTop: -12 }}>
              <Typography variant="h3">FRES PEREZ S.L</Typography>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <TextField id="database" label="Base de datos" value={formValues.db} onChange={(e) => onTextFieldChange("db", e)} />
          </Grid>
          <Grid item>
            <TextField id="username" error={formState.error} label="Usuario" autoFocus value={formValues.login} onChange={(e) => onTextFieldChange("login", e)} />
          </Grid>
          <Grid item>
            <TextField
              id="password"
              error={formState.error}
              helperText={formState.helperText}
              label="Contraseña"
              type="password"
              value={formValues.password}
              onChange={(e) => onTextFieldChange("password", e)}
            />
          </Grid>

          <Grid item>
            <ActionButton type="submit" loading={loading} onClick={login}>
              Iniciar Sesión
            </ActionButton>
          </Grid>
        </Grid>
        <Typography variant="subtitle1" className={classes.version}>
          v2.0.1.15
        </Typography>
      </form>
    </React.Fragment>
  );
};

export default LoginScreen;
