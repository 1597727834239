// const formatDate = (date) => {
//   var hours = date.getHours();
//   var minutes = date.getMinutes();
//   var ampm = hours >= 12 ? "pm" : "am";
//   hours = hours % 12;
//   hours = hours ? hours : 12; // the hour '0' should be '12'
//   minutes = minutes < 10 ? "0" + minutes : minutes;
//   var strTime = hours + ":" + minutes + " " + ampm;
//   return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear() + "  " + strTime;
// };

const formatDate = (date) => {
  const format = (value) => ("0" + value).slice(-2);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();
  var strTime = format(hours) + ":" + format(minutes) + ":" + format(seconds);
  return format(date.getDate()) + "/" + format(date.getMonth() + 1) + "/" + date.getFullYear() + " - " + strTime;
};

export { formatDate };
