import { forwardRef, useImperativeHandle } from "react";
import ActionButton from "./ActionButton";
import { useHistory } from "react-router-dom";

const BackButton = forwardRef((_, ref) => {
  const history = useHistory();

  useImperativeHandle(ref, () => ({
    goBack() {
      history.goBack();
    },
  }));

  return (
    <ActionButton action="danger" onClick={() => history.goBack()}>
      Volver
    </ActionButton>
  );
});

export default BackButton;
