import React from "react";
import { orderBy } from "lodash";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Modal, ErrorModal, Keypad, StockCard, ProductCard, CardGridList } from "../../components";
import { useMutation } from "@apollo/client";
import PaletGrid from "./PaletGrid";
import { CREATE_PALET_LINE } from "../../apollo/mutations";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "80.8vh",
  },
  header: {
    padding: "2px 10px",
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
}));

const StockPanel = ({ data, loading, refetch, palet, onNewPaletLine, enable }) => {
  const classes = useStyles();
  const errorModal = React.useRef();
  const [openProduceProduct, setOpenProduceProduct] = React.useState(false);
  const [openKeypad, setOpenKeypad] = React.useState(false);
  const [error, setError] = React.useState("");
  const [createLine] = useMutation(CREATE_PALET_LINE, {
    ignoreResults: true,
    onCompleted: () => {
      refetch();
      onNewPaletLine();
    },
  });
  const [stock, setStock] = React.useState(false);
  const [quantity, setQuantity] = React.useState(false);
  const [kilosSelected, setKilosSelected] = React.useState(false);
  const [uom, setUom] = React.useState("");

  const onCardClick = (stock) => {
    setStock(stock);
    setUom("Cajas");
    setOpenKeypad(true);
  };

  const onCloseKeypad = () => {
    setOpenKeypad(false);
    resetState();
  };

  const onAccept = (qty) => {
    setOpenKeypad(false);
    if (stock.productId.produceProducts.length > 1) {
      setOpenProduceProduct(true);
      setQuantity(qty);
    } else if (stock.uom === "kg" && !kilosSelected) {
      setKilosSelected(true);
      setUom(stock.uom);
      setOpenKeypad(true);
      setQuantity(qty);
    } else {
      if (qty !== 0.0) {
        createPaletLine(quantity, quantity ? quantity : qty, qty);
      }
      setKilosSelected(false);
      setQuantity(false);
    }
  };

  const createPaletLine = async (hasKilos, qty, kilos, produceProduct = false) => {
    try {
      let variables = {
        paletId: palet.id,
        paletLine: {
          moveLineId: stock.id,
          quantity: qty,
        },
      };

      if (hasKilos) variables.paletLine["kilos"] = kilos;
      if (produceProduct) variables.paletLine["produceProduct"] = produceProduct;

      await createLine({ variables });
    } catch (err) {
      setError(err.message);
      errorModal.current.openModal();
    }
  };

  const onCloseProduceProduct = (produceProduct) => {
    setOpenProduceProduct(false);
    if (produceProduct) {
      createPaletLine(false, quantity, false, produceProduct.id);
      setKilosSelected(false);
      setQuantity(false);
    } else {
      resetState();
    }
  };

  const resetState = () => {
    setQuantity(false);
    setKilosSelected(false);
  };
  return (
    <React.Fragment>
      <div className={classes.header}>
        <Typography variant="subtitle1" style={{ fontSize: 25 }}>
          Fruta Disponible
        </Typography>
      </div>
      <PaletGrid cols={1} data={data && data.stock} onClick={onCardClick} loading={loading} height="93.8%" enable={enable} />
      <Modal open={openKeypad} onClose={onCloseKeypad} minWidth={888}>
        <Keypad onAccept={onAccept} uom={uom} limitValue={uom === stock.uom ? stock.quantity : false}>
          <StockCard disableRipple stock={stock} />
        </Keypad>
      </Modal>
      <Modal open={openProduceProduct} onClose={onCloseProduceProduct} fullWidth maxWidth={false}>
        <CardGridList>
          {stock &&
            orderBy(stock.productId.produceProducts, ["priority"], ["desc"]).map((produceProduct) => (
              <ProductCard showAvatar={false} key={produceProduct.id} product={produceProduct} onClick={() => onCloseProduceProduct(produceProduct)} />
            ))}
        </CardGridList>
      </Modal>
      <ErrorModal ref={errorModal} error={error} />
    </React.Fragment>
  );
};

export default StockPanel;
