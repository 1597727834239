import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { getContrastText } from "../../utils";

const styles = {
  button: {
    width: "100%",
    height: 131,
  },
  leftContainer: {
    textAlign: "left",
  },
  rightContainer: {
    textAlign: "right",
  },
  subtitle: {
    position: "relative",
    top: -10,
    opacity: 0.75,
  },
};

const FarmCard = ({ parcel, onClick }) => {
  const useStyles = makeStyles({
    ...styles,
    buttonColor: {
      backgroundColor: parcel.color,
      "&:hover": {
        backgroundColor: parcel.color,
      },
      color: getContrastText(parcel.color),
    },
  });
  const classes = useStyles();
  return (
    <Button variant="outlined" disableElevation className={clsx(classes.button, classes.buttonColor)} {...{ onClick }}>
      <Grid container justify="space-between">
        <Grid item className={classes.leftContainer}>
          <Typography variant="caption">{parcel.name}</Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            {parcel.farmId.name}
          </Typography>
        </Grid>
        <Grid item className={classes.rightContainer}>
          <Typography variant="caption">{parcel.description}</Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            {parcel.description ? "Descripción" : ""}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );
};

FarmCard.propTypes = {
  parcel: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onClick: PropTypes.func,
};

FarmCard.defaultProps = {
  parcel: {
    name: "",
    farmId: {
      name: "",
    },
  },
  onClick: () => {},
};

export default FarmCard;
