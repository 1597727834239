import React from "react";
import { Route, Redirect } from "react-router-dom";
import { IS_LOGGED } from "../../apollo/queries";
import { useQuery } from "@apollo/client";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { loading, error } = useQuery(IS_LOGGED, {
    errorPolicy: "all",
    fetchPolicy: "no-cache",
  });

  if (loading) return <p>Loading...</p>;

  if (error) return <Route {...rest} render={() => <Redirect to="/login" />} />;

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default PrivateRoute;
