import React from "react";
import PropTypes from "prop-types";
import ActionButton from "./ActionButton";
import { SignModal } from "../../components";

const SignButton = ({ onSigned }) => {
  const [openSignModal, setOpenSignModal] = React.useState(false);

  const onSignModalResult = (signature) => {
    setOpenSignModal(false);
    if (signature) onSigned(signature);
  };

  return (
    <React.Fragment>
      <ActionButton action="terciary" onClick={() => setOpenSignModal(true)}>
        Firmar
      </ActionButton>
      <SignModal open={openSignModal} onResult={onSignModalResult} />
    </React.Fragment>
  );
};

SignButton.propTypes = {
  onSigned: PropTypes.func,
};

SignButton.defaultProps = {
  onSigned: () => {},
};

export default SignButton;
