import React from "react";
import { orderBy } from "lodash";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { BackButton, ActionButton, PrinterButton, SecureButton, PaletCard, DataTable, Paper, PartnerModal, DeleteModal, CardGridList } from "../../components";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { GET_SALE } from "../../apollo/queries";
import { UPDATE_PARTNER_SALE_ORDER, PALET_SALE_ORDER, UPDATE_SALE_ORDER_STATE } from "../../apollo/mutations";
import { formatMoney, formatQuantity } from "../../utils";
import LinkPaletSaleButton from "./LinkPaletSaleButton";
import CreateCmrButton from "./CreateCmrButton";
import { getContrastText } from "../../utils";

const useStyles = makeStyles((theme) => ({
  centerButton: {
    marginRight: theme.margin(0),
    marginLeft: theme.margin(0),
  },
  button: {
    marginLeft: theme.margin(0),
  },
  root: {
    width: "100%",
    height: "55.5vh",
  },
  rootComplete: {
    height: "70vh",
  },
  container: {
    overflowY: "auto",
    height: "100%",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  leftHeader: {
    borderRight: `1px solid ${theme.palette.grey[400]}`,
    minHeight: 50,
    padding: 12,
    textAlign: "center",
  },
  centerHeader: {
    minHeight: 50,
    padding: 12,
    textAlign: "center",
  },
  buttonCenterHeader: {
    borderRight: `1px solid ${theme.palette.grey[400]}`,
    height: "100%",
    width: "100%",
  },
}));

const columns = [
  { id: "productId", label: "PRODUCTO", minWidth: 170, format: (value) => value.code },
  { id: "quantity", label: "CAJAS", minWidth: 100, align: "center" },
  { id: "kilos", label: "KILOS", minWidth: 170, align: "center", format: (value) => formatQuantity(value) },
  { id: "priceUnit", label: "PRECIO", minWidth: 170, format: (value) => formatMoney(value) },
];

const SaleDetailScreen = ({ location }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { data, refetch, loading } = useQuery(GET_SALE, {
    variables: { id },
    fetchPolicy: "cache-and-network",
    pollInterval: 30000,
  });

  const onCompleted = () => refetch();
  const [openPartner, setOpenPartner] = React.useState(false);
  const [openPaletDelete, setOpenPaletDelete] = React.useState(false);

  const [viewPalets, setViewPalets] = React.useState(true);
  const [paletSelected, setPaletSelected] = React.useState(false);

  const [updateSaleOrderState] = useMutation(UPDATE_SALE_ORDER_STATE, { ignoreResults: true, onCompleted });
  const [updateSaleOrderPartner] = useMutation(UPDATE_PARTNER_SALE_ORDER, { ignoreResults: true, onCompleted });
  const [paletSaleOrder] = useMutation(PALET_SALE_ORDER, { ignoreResults: true, onCompleted });

  const backButtonRef = React.useRef();
  const paletLinkRef = React.useCallback(
    (node) => {
      if (node && location.state && location.state.create) {
        node.openPaletDialog();
        location.state = undefined;
      }
    },
    [location]
  );

  const updateState = async (state) => {
    try {
      const variables = { id, state };
      await updateSaleOrderState({ variables });
      let actions = {
        CONFIRM: { message: "Venta confirmada correctamente.", variant: "success" },
        REVERSE: { message: "Venta convertida a borrador correctamente.", variant: "info" },
        DONE: { message: "Venta entregada correctamente.", variant: "success" },
      };
      enqueueSnackbar(actions[state].message, {
        variant: actions[state].variant,
        anchorOrigin: { vertical: state === "DONE" ? "bottom" : "top", horizontal: "center" },
        autoHideDuration: 1500,
      });
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
    }
  };

  const updatePartner = async (partner) => {
    try {
      const variables = { partnerId: partner, saleOrderId: parseInt(id) };
      await updateSaleOrderPartner({ variables });
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
    }
  };

  const paletSale = async (palet, operation) => {
    try {
      const variables = { paletId: palet, saleOrderId: parseInt(id), operation };
      await paletSaleOrder({ variables });
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
    }
  };

  const onCardClick = (palet) => {
    if (data && data.sale.state === "draft") {
      setOpenPaletDelete(true);
      setPaletSelected(palet);
    }
  };

  const onPartnerResult = (partner) => {
    setOpenPartner(false);
    if (partner) updatePartner(partner.id);
  };

  const onPaletDeleteResult = (palet) => {
    setOpenPaletDelete(false);
    if (palet) paletSale(palet.id, "UNLINK");
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid container>
          <Grid item sm>
            <BackButton ref={backButtonRef} />
          </Grid>
          <Grid item sm className={classes.centerButton}>
            <PrinterButton pdf={`sale.report_saleorder/${id}`} />
          </Grid>
          {data && data.sale.state === "draft" && (
            <Grid item sm>
              <LinkPaletSaleButton saleOrderId={id} onLinkedPalet={() => refetch()} ref={paletLinkRef} />
            </Grid>
          )}
          {data && data.sale.state !== "draft" && (
            <Grid item sm>
              <CreateCmrButton saleOrderId={id} cmrId={data && data.sale.cmrId.id} />
            </Grid>
          )}
        </Grid>
        <Grid container item style={{ marginTop: 20 }}>
          <Grid item sm>
            <Paper>
              <Grid container>
                <Grid item sm={3}>
                  <Typography variant="h6" className={classes.leftHeader}>
                    {data && data.sale.name}
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <ButtonBase
                    disabled={data && data.sale.state !== "draft"}
                    onClick={() => {
                      if (data && data.sale.state === "draft") setOpenPartner(true);
                    }}
                    className={classes.buttonCenterHeader}
                    style={{
                      backgroundColor: data && data.sale.partnerId.color,
                      color: getContrastText(data && data.sale.partnerId.color),
                    }}
                  >
                    <Typography variant="h6" className={classes.centerHeader}>
                      {data && data.sale.partnerId.name}
                    </Typography>
                  </ButtonBase>
                </Grid>
                <Grid item sm={3}>
                  <ButtonBase onClick={() => setViewPalets(!viewPalets)} style={{ height: "100%", width: "100%" }}>
                    <Typography variant="h6" style={{ marginLeft: 5, marginRight: 5 }}>
                      {viewPalets ? "VER LINEAS" : "VER PALETS"}
                    </Typography>
                  </ButtonBase>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 20 }}>
          <Grid item sm>
            {viewPalets ? (
              <Paper className={clsx(data && data.sale.state === "sold" ? classes.rootComplete : classes.root)}>
                <div className={classes.container}>
                  <CardGridList style={{ padding: 10 }}>
                    {data && orderBy(data.sale.palets, ["name"], ["desc"]).map((palet) => <PaletCard key={palet.id} palet={palet} onClick={() => onCardClick(palet)} />)}
                  </CardGridList>
                </div>
              </Paper>
            ) : (
              <DataTable loading={loading} columns={columns} rows={data && data.sale.orderLines} height={data && data.sale.state === "sold" ? "70vh" : "55.5vh"} />
            )}
          </Grid>
        </Grid>

        {data && data.sale.state !== "sold" && (
          <Grid container item style={{ marginTop: 20 }}>
            {data && data.sale.state !== "draft" && !data.sale.cmrId.id && (
              <Grid item sm>
                <ActionButton action="danger" onClick={() => updateState("REVERSE")}>
                  Borrador
                </ActionButton>
              </Grid>
            )}
            <Grid item sm className={(data && data.sale.state !== "draft" && !data.sale.cmrId.id && classes.button) || ""}>
              {data && data.sale.state === "draft" ? (
                <ActionButton action="primary" onClick={() => updateState("CONFIRM")}>
                  Confirmar
                </ActionButton>
              ) : (
                <SecureButton
                  acceptButton={{ name: "Aceptar", action: "primary" }}
                  cancelButton={{ name: "Cancelar", action: "danger" }}
                  message={`¿Seguro que desea marca la venta '${data && data.sale.name}' como entregada?`}
                  action="primary"
                  onResult={() => updateState("DONE")}
                >
                  Entregado
                </SecureButton>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
      <PartnerModal customer open={openPartner} onResult={onPartnerResult} />
      <DeleteModal data={paletSelected} open={openPaletDelete} onResult={onPaletDeleteResult}>
        <PaletCard disableRipple palet={paletSelected} style={{ marginTop: 10, marginBottom: 25 }} />
      </DeleteModal>
    </React.Fragment>
  );
};

export default SaleDetailScreen;
