import { withStyles } from "@material-ui/core/styles";
import PaperUI from "@material-ui/core/Paper";

const StyledPaper = withStyles((theme) => ({
  root: {
    borderRadius: 0,
    border: `1px solid ${theme.palette.grey[400]}`,
  },
}))(PaperUI);

const Paper = ({ children, ...props }) => {
  return (
    <StyledPaper elevation={0} {...props}>
      {children}
    </StyledPaper>
  );
};

export default Paper;
