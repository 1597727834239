import React from "react";
import axios from "axios";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ActionButton } from "../../components";
import { useHistory } from "react-router-dom";
import client from "../../apollo";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    width: "50%",
    height: "50%",
  },
  logoutContainer: {
    position: "absolute",
    height: 100,
    width: 300,
    top: "calc(50% - (100px/2))",
    left: "calc(50% - (300px/2))",
  },
  reception: {
    backgroundColor: theme.palette.reception.main,
    "&:hover": {
      backgroundColor: theme.palette.reception.dark,
    },
  },
  purchase: {
    backgroundColor: theme.palette.purchase.main,
    "&:hover": {
      backgroundColor: theme.palette.purchase.dark,
    },
  },
  palet: {
    backgroundColor: theme.palette.palet.main,
    "&:hover": {
      backgroundColor: theme.palette.palet.dark,
    },
  },
  sale: {
    backgroundColor: theme.palette.sale.main,
    "&:hover": {
      backgroundColor: theme.palette.sale.dark,
    },
  },
}));

const MenuScreen = () => {
  const history = useHistory();
  const classes = useStyles();

  const pushHistory = (route) => {
    history.push(`/${route}`);
  };

  const logout = async () => {
    try {
      await axios.post("/web/session/destroy", {}, { headers: { "Content-Type": "application/json" } });
    } finally {
      history.push("/login");
      client.resetStore();
    }
  };

  return (
    <React.Fragment>
      <div className={classes.container} style={{ top: 0, left: 0 }}>
        <ActionButton className={classes.reception} onClick={() => pushHistory("receptions")}>
          ENTRADA FRUTA
        </ActionButton>
      </div>
      <div className={classes.container} style={{ top: 0, left: "50%" }}>
        <ActionButton className={classes.purchase} onClick={() => pushHistory("purchases")}>
          COMPRAS ENVASES
        </ActionButton>
      </div>
      <div className={classes.container} style={{ top: "50%", left: 0 }}>
        <ActionButton className={classes.palet} onClick={() => pushHistory("palets")}>
          CONFECCIÓN PALETS
        </ActionButton>
      </div>
      <div className={classes.container} style={{ top: "50%", left: "50%" }}>
        <ActionButton className={classes.sale} onClick={() => pushHistory("sales")}>
          VENTAS
        </ActionButton>
      </div>
      <div className={classes.logoutContainer}>
        <ActionButton action="danger" onClick={logout}>
          SALIR
        </ActionButton>
      </div>
    </React.Fragment>
  );
};

export default MenuScreen;
