const formatMoney = (money) => {
  return money.toLocaleString("es-ES", { minimumFractionDigits: 2 }) + " €";
};

// const formatDiscount = (discount) => {
//   return discount.toLocaleString("es-ES", { minimumFractionDigits: 2 }) + " %";
// };

const formatQuantity = (quantity) => {
  return quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export { formatMoney, formatQuantity };
