import React from "react";
import { orderBy } from "lodash";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { useQuery, useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { GET_CARRIER_COMPANIES } from "../../apollo/queries";
import { Modal, CarrierCard, Keyboard } from "../../components";
import { CREATE_CARRIER_COMPANY } from "../../apollo/mutations";
import CardGridList from "../common/CardGridList";

const FIELDS = [
  {
    field: "name",
    label: "Empresa de transporte",
  },
  {
    field: "nif",
    label: "NIF Empresa de transporte",
  },
];

const CarrierModal = ({ open, onResult, onClose, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, refetch } = useQuery(GET_CARRIER_COMPANIES);
  const [openKeyboard, setOpenKeyboard] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const [currentField, setCurrentField] = React.useState(false);
  const [variables, setVariables] = React.useState({});
  const [createCarrier] = useMutation(CREATE_CARRIER_COMPANY, {
    onCompleted: (result) => {
      onResult(result.company.carrier);
      resetState();
      refetch();
    },
  });

  const onKeyboardResult = (result) => {
    setOpenKeyboard(false);
    setVariables({ ...variables, [currentField.field]: result });
    if (!showNextField()) createCarrierCompany({ ...variables, [currentField.field]: result });
  };

  const createCarrierCompany = async (values) => {
    try {
      const vals = { carrierCompany: values };
      await createCarrier({ variables: vals });
    } catch (error) {
      enqueueSnackbar("Se produjo un error", { variant: "error" });
      console.log(error);
    }
  };

  const showNextField = () => {
    if (index < FIELDS.length) {
      setCurrentField({ field: FIELDS[index].field, label: FIELDS[index].label });
      setOpenKeyboard(true);
      setIndex(index + 1);
      return true;
    }
    return false;
  };

  const resetState = () => {
    setIndex(0);
    setVariables({});
  };

  return (
    <React.Fragment>
      <Modal open={open} onClose={onClose} fullWidth maxWidth={false} {...props}>
        <CardGridList>
          {data && orderBy(data.companies, ["name"], ["asc"]).map((carrier) => <CarrierCard key={carrier.id} carrier={carrier} onClick={() => onResult(carrier)} />)}
          <CarrierCard create onClick={() => showNextField()} />
        </CardGridList>
      </Modal>
      <Modal open={openKeyboard} onClose={() => setOpenKeyboard(false)} minWidth={1580}>
        <Keyboard onResult={onKeyboardResult}>
          <Typography variant="subtitle2" style={{ marginBottom: 15 }}>
            {currentField.label}
          </Typography>
        </Keyboard>
      </Modal>
    </React.Fragment>
  );
};

CarrierModal.propTypes = {
  open: PropTypes.bool,
  onResult: PropTypes.func,
  onClose: PropTypes.func,
};

CarrierModal.defaultProps = {
  open: false,
  onResult: () => {},
  onClose: () => {},
};

export default CarrierModal;
