import React from "react";
import Grid from "@material-ui/core/Grid";
import ActionButton from "./ActionButton";
import PropTypes from "prop-types";
import Modal from "../modals/Modal";
import Typography from "@material-ui/core/Typography";

const SecureButton = ({ message, acceptButton, cancelButton, onResult, children, ...props }) => {
  const [open, setOpen] = React.useState(false);
  const showDialog = () => {
    setOpen(true);
  };
  const onClose = (value) => {
    setOpen(false);
    if (value) onResult();
  };

  return (
    <React.Fragment>
      <ActionButton action="danger" onClick={showDialog} {...props}>
        {children}
      </ActionButton>
      <Modal open={open} onClose={onClose} minWidth={1000}>
        <Typography variant="subtitle2" style={{ textAlign: "center" }}>
          <p>{message}</p>
        </Typography>
        <Grid container spacing={2}>
          <Grid item sm>
            <ActionButton action={cancelButton.action} onClick={() => onClose(false)}>
              {cancelButton.name}
            </ActionButton>
          </Grid>
          <Grid item sm>
            <ActionButton action={acceptButton.action} onClick={() => onClose(true)}>
              {acceptButton.name}
            </ActionButton>
          </Grid>
        </Grid>
      </Modal>
    </React.Fragment>
  );
};

SecureButton.propTypes = {
  message: PropTypes.string,
  acceptButton: PropTypes.object,
  cancelButton: PropTypes.object,
  onResult: PropTypes.func,
};

SecureButton.defaultProps = {
  message: "¿Seguro que desear eliminar?",
  acceptButton: {
    name: "Aceptar",
    action: "success",
  },
  cancelButton: {
    name: "Cancelar",
    action: "danger",
  },
};

export default SecureButton;
