import React from "react";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import Typography from "@material-ui/core/Typography";
import { CREATE_CMR } from "../../apollo/mutations";
import { ActionButton, Modal, Keypad, Keyboard, CarrierModal } from "../../components";
import { FIELDS } from "./Fields";

const CreateCmrButton = ({ saleOrderId, cmrId, ...props }) => {
  const [openKeypad, setOpenKeypad] = React.useState(false);
  const [openKeyboard, setOpenKeyboard] = React.useState(false);
  const [openCarrierModal, setOpenCarrierModal] = React.useState(false);
  const [index, setIndex] = React.useState(0);
  const [currentField, setCurrentField] = React.useState(false);
  const [variables, setVariables] = React.useState({});
  const [createCMR] = useMutation(CREATE_CMR, { onCompleted: (result) => history.push(`/cmr/${result.createCmr}`) });

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const onKeypadResult = (result) => {
    setOpenKeypad(false);
    if (result) setVariables({ ...variables, [currentField.field]: result });
    if (!showNextField()) onCreateCMR({ [currentField.field]: result });
  };

  const onKeyboardResult = (result) => {
    setOpenKeyboard(false);
    if (result) setVariables({ ...variables, [currentField.field]: result });
    if (!showNextField()) onCreateCMR({ [currentField.field]: result });
  };

  const onCarrierResult = (carrier) => {
    setOpenCarrierModal(false);
    if (carrier) setVariables({ ...variables, [currentField.field]: carrier.id });
    if (!showNextField()) onCreateCMR({ [currentField.field]: carrier.id });
  };

  const onCmrClick = () => {
    if (cmrId) {
      history.push(`/cmr/${cmrId}`);
    } else {
      showNextField();
    }
  };

  const onCreateCMR = async (val) => {
    setIndex(0);
    try {
      const vals = { saleOrderId: parseInt(saleOrderId), inputCmr: { ...variables, ...val } };
      await createCMR({ variables: vals });
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
    }
  };

  const showNextField = () => {
    if (index < FIELDS.length) {
      setCurrentField({ field: FIELDS[index].field, label: FIELDS[index].label, uom: FIELDS[index].uom });
      if (FIELDS[index].type === "str") {
        setOpenKeyboard(true);
      } else if (FIELDS[index].type === "int") {
        setOpenKeypad(true);
      } else {
        setOpenCarrierModal(true);
      }
      setIndex(index + 1);
      return true;
    }
    return false;
  };

  const resetState = () => {
    setIndex(0);
    setVariables({});
  };

  return (
    <React.Fragment>
      <ActionButton action="success" onClick={() => onCmrClick()} {...props}>
        {cmrId ? "Ver CMR" : "Crear CMR"}
      </ActionButton>
      <Modal
        open={openKeypad}
        onClose={() => {
          setOpenKeypad(false);
          resetState();
        }}
        minWidth={888}
      >
        <Keypad onAccept={onKeypadResult} uom={currentField && currentField.uom ? currentField.uom : "Unidades"}>
          <Typography variant="subtitle2">{currentField && currentField.label}</Typography>
        </Keypad>
      </Modal>
      <Modal
        open={openKeyboard}
        onClose={() => {
          setOpenKeyboard(false);
          resetState();
        }}
        minWidth={1580}
      >
        <Keyboard onResult={onKeyboardResult}>
          <Typography variant="subtitle2" style={{ marginBottom: 15 }}>
            {currentField && currentField.label}
          </Typography>
        </Keyboard>
      </Modal>
      <CarrierModal
        open={openCarrierModal}
        onResult={onCarrierResult}
        onClose={() => {
          setOpenCarrierModal(false);
          resetState();
        }}
      />
    </React.Fragment>
  );
};

export default CreateCmrButton;
