import React from "react";
import { orderBy } from "lodash";
import PropTypes from "prop-types";
import Modal from "./Modal";
import PaletCard from "../cards/PaletCard";
import CardGridList from "../common/CardGridList";

const PaletModal = ({ data, open, onResult, ...props }) => {
  return (
    <Modal open={open} onClose={onResult} fullWidth maxWidth={false} {...props}>
      <CardGridList>{data && orderBy(data.palets, ["name"], ["desc"]).map((palet) => <PaletCard key={palet.id} palet={palet} onClick={() => onResult(palet)} />)}</CardGridList>
    </Modal>
  );
};

PaletModal.propTypes = {
  open: PropTypes.bool,
  onResult: PropTypes.func,
};

PaletModal.defaultProps = {
  open: false,
  onResult: () => {},
};

export default PaletModal;
