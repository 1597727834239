import { ApolloClient, InMemoryCache } from "@apollo/client";

const client = new ApolloClient({
  uri: "/graphql",
  cache: new InMemoryCache({
    typePolicies: {
      Reception: {
        fields: {
          lines: {
            merge: false,
          },
        },
      },
      PurchaseOrder: {
        fields: {
          orderLines: {
            merge: false,
          },
        },
      },
      SaleOrder: {
        fields: {
          palets: {
            merge: false,
          },
          orderLines: {
            merge: false,
          },
        },
      },
      Palet: {
        fields: {
          lines: {
            merge: false,
          },
        },
      },
    },
  }),
});

export default client;
