import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { getContrastText } from "../../utils";

const styles = {
  button: {
    height: 131,
    width: "100%",
    position: "relative",
  },
  leftContainer: {
    textAlign: "left",
    position: "relative",
  },
  rightContainer: {
    textAlign: "right",
  },
  subtitle: {
    position: "relative",
    top: -10,
    opacity: 0.95,
    width: "100%",
  },
  number: {
    fontWeight: 700,
  },
  subtitleWrapper: {
    position: "absolute",
    top: 60,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "&:hover": {
      overflow: "visible",
    },
  },
  overlay: {
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    fontStyle: "italic",
    zIndex: 1,
  },
  overlayText: {
    backgroundColor: "green",
    color: "white",
    width: "100%",
    transform: "rotate(24deg)",
    boxShadow: "0px 2px 5px rgba(0,0,0,0.4)",
  },
};

const PaletCard = ({ palet, showSaleState, onClick, ...props }) => {
  const useStyles = makeStyles((theme) => ({
    ...styles,
    progress: {
      color: "black",
      backgroundColor: theme.palette.grey[50],
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
    done: {
      color: palet.partnerId.color ? getContrastText(palet.partnerId.color) : "white",
      backgroundColor: palet.partnerId.color ? palet.partnerId.color : theme.palette.success.main,
      "&:hover": {
        backgroundColor: palet.partnerId.color ? palet.partnerId.color : theme.palette.success.dark,
      },
    },
    overlayBackground: {
      opacity: ["assigned"].includes(palet.state) && showSaleState ? 0.65 : 1,
    },
  }));
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      disableElevation
      className={clsx(
        classes.button,
        classes[(["assigned", "sold"].includes(palet.state) && !showSaleState) || (["assigned"].includes(palet.state) && showSaleState) ? "done" : palet.state]
      )}
      onClick={() => onClick(palet)}
      {...props}
    >
      {palet.state === "assigned" && showSaleState && (
        <div className={classes.overlay}>
          <Typography variant="overline" className={classes.overlayText}>
            {palet.orderId.name}
          </Typography>
        </div>
      )}
      <Grid container justify="space-between" className={classes.overlayBackground}>
        <Grid item xs={7} className={classes.leftContainer}>
          <Typography variant="overline">{palet.name}</Typography>
          <Typography variant="subtitle1" className={clsx(classes.subtitle, classes.subtitleWrapper)}>
            {palet.partnerId.name}
          </Typography>
        </Grid>
        <Grid item xs={5} className={classes.rightContainer}>
          <Typography variant="overline">Palet. {palet.code}</Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            <span className={classes.number}>{palet.totalQuantity}</span> CAJAS - (<span className={classes.number}>{palet.totalKilos}</span> KG)
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );
};

PaletCard.propTypes = {
  palet: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  showSaleState: PropTypes.bool,
  onClick: PropTypes.func,
};

PaletCard.defaultProps = {
  palet: {
    name: "PALET/00000",
    code: "0",
    partnerId: {
      name: "",
    },
    totalQuantity: 0,
    totalKilos: 0,
    lines: [],
    state: "",
  },
  onClick: () => {},
};

export default PaletCard;
