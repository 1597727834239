import MaterialTextField from "@material-ui/core/TextField";

const TextField = ({ children, ...props }) => {
  return (
    <MaterialTextField fullWidth {...props} inputProps={{ style: { fontSize: 50, height: 85, marginBottom: 0 } }} InputLabelProps={{ style: { fontSize: 45 } }}>
      {children}
    </MaterialTextField>
  );
};

export default TextField;
