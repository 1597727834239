import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ActionButton from "../buttons/ActionButton";
import ErrorModal from "../modals/ErrorModal";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  display: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[400]}`,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    width: 1535,
  },
  keyContainer: {
    backgroundColor: theme.palette.warning.main,
  },
}));

const KEYS_MAP = [["1234567890"], ["qwertyuiop"], ["asdfghjklñ"], ["zxcvbnm,.-"]];

const Keyboard = ({ onResult, defaultValue, placeholder, required, children }) => {
  const errorModal = React.useRef();
  const [value, setValue] = React.useState(defaultValue);
  const [deleteDefaultValue, setDeleteDefaultValue] = React.useState(defaultValue !== "");
  const [hasPlaceHolder, setPlaceHolder] = React.useState(defaultValue === "" && placeholder);

  const classes = useStyles();
  let marginCounter = -33.5;

  const onNumberClick = (key) => {
    if (hasPlaceHolder) setPlaceHolder(false);

    if (deleteDefaultValue) {
      setValue("".concat(key));
      setDeleteDefaultValue(false);
    } else {
      if (value.length < 30) setValue(value.concat(key));
    }
  };

  const onDeleteClick = () => {
    setValue("");
    setPlaceHolder(true);
  };

  const onBackClick = () => {
    if (value.length === 1) setPlaceHolder(true);
    setDeleteDefaultValue(false);
    setValue(value.substring(0, value.length - 1));
  };

  const onConfirm = () => {
    if (required && value.length === 0) {
      errorModal.current.openModal();
    } else {
      onResult(value.toUpperCase());
      onDeleteClick();
    }
  };

  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.header}>{children}</div>
        <Grid container style={{ height: 110 /*740*/ }}>
          <Grid container item>
            <Grid item sm={8}>
              <div className={classes.display} style={{ opacity: hasPlaceHolder ? 0.5 : 1 }}>
                <Typography variant="h4">{hasPlaceHolder ? placeholder : value}</Typography>
              </div>
            </Grid>
            <Grid item sm={4}>
              <ActionButton action="danger" onClick={onBackClick} onLongClick={onDeleteClick}>
                BORRAR
              </ActionButton>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.keyContainer}>
          {KEYS_MAP.map((keys) => {
            marginCounter = marginCounter + 33.5;
            return (
              <Grid key={keys} container item style={{ marginLeft: marginCounter, width: 1434 }}>
                {keys.map((key) =>
                  [...key].map((k) => (
                    <Grid key={k} item sm>
                      <ActionButton action="warning" onClick={() => onNumberClick(k)}>
                        {k}
                      </ActionButton>
                    </Grid>
                  ))
                )}
              </Grid>
            );
          })}
          <Grid container item>
            <Grid item sm>
              <ActionButton action="danger" onClick={() => onResult()}>
                CANCELAR
              </ActionButton>
            </Grid>
            <Grid item sm>
              <ActionButton action="warning" onClick={() => setValue(value + " ")}>
                ESPACIO
              </ActionButton>
            </Grid>
            <Grid item sm>
              <ActionButton action="success" onClick={onConfirm}>
                ACEPTAR
              </ActionButton>
            </Grid>
          </Grid>
        </div>
      </div>
      <ErrorModal ref={errorModal} error="¡Este campo es obligatorio!" />
    </React.Fragment>
  );
};

Keyboard.propTypes = {
  onResult: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  children: PropTypes.node,
};

Keyboard.defaultProps = {
  onResult: () => {},
  defaultValue: "",
};

export default Keyboard;
