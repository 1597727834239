import { orderBy } from "lodash";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { GET_DESTINY_PARTNERS } from "../../apollo/queries";
import Modal from "./Modal";
import PartnerCard from "../cards/PartnerCard";
import CardGridList from "../common/CardGridList";

const DestinyPartnerModal = ({ partnerId, open, onResult, onRowCount, ...props }) => {
  const { data } = useQuery(GET_DESTINY_PARTNERS, {
    variables: { partnerId },
    skip: !partnerId,
    onCompleted: () => onRowCount(data.destinyPartners.length),
  });

  return (
    <Modal open={open} onClose={onResult} fullWidth maxWidth={false} {...props}>
      <CardGridList>
        {data && orderBy(data.destinyPartners, ["name"], ["asc"]).map((partner) => <PartnerCard key={partner.id} partner={partner} onClick={() => onResult(partner)} />)}
      </CardGridList>
    </Modal>
  );
};

DestinyPartnerModal.propTypes = {
  partnerId: PropTypes.number,
  open: PropTypes.bool,
  onResult: PropTypes.func,
  onRowCount: PropTypes.func,
};

DestinyPartnerModal.defaultProps = {
  onResult: () => {},
  onRowCount: () => {},
};

export default DestinyPartnerModal;
