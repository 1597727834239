import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { getContrastText } from "../../utils";

const styles = {
  button: {
    width: "100%",
    textTransform: "uppercase",
  },
  leftContainer: {
    textAlign: "left",
  },
  rightContainer: {
    textAlign: "right",
  },
  title: {
    fontSize: 40,
    fontWeight: 400,
  },
  subtitle: {
    width: "100%",
    fontSize: 30,
    fontWeight: 400,
    position: "relative",
    top: -15,
    opacity: 0.85,
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "&:hover": {
      overflow: "visible",
    },
  },
  bold: {
    fontWeight: 700,
  },
};

const StockCard = ({ stock, ...props }) => {
  const useStyles = makeStyles({
    ...styles,
    buttonColor: {
      backgroundColor: stock.locationId.parcelId.color,
      "&:hover": {
        backgroundColor: stock.locationId.parcelId.color,
      },
      color: getContrastText(stock.locationId.parcelId.color),
    },
  });

  const classes = useStyles();
  return (
    <Button variant="outlined" disableElevation className={clsx(classes.button, classes.buttonColor)} {...props}>
      <Grid container justify="space-between">
        <Grid item xs={4} className={classes.leftContainer}>
          <span className={clsx(classes.title, classes.bold)}>{stock.locationId.name}</span>
          <span className={classes.subtitle}>{stock.locationId.parentName}</span>
        </Grid>
        <Grid item xs={8} className={classes.rightContainer}>
          <span className={classes.title}>
            <span className={classes.bold}>{String(stock.uom).toUpperCase() === "CAJAS" || !("kilos" in stock) ? stock.quantity : stock.kilos}</span> {stock.uom}
          </span>
          <span className={classes.subtitle}>{stock.productId.code}</span>
        </Grid>
      </Grid>
    </Button>
  );
};

StockCard.propTypes = {
  stock: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onClick: PropTypes.func,
};

StockCard.defaultProps = {
  stock: {
    productId: {
      code: "",
    },
    locationId: {
      name: "",
      parentName: "",
      parcelId: {
        color: "",
      },
    },
    lotId: {
      name: "",
    },
    quantity: -1,
    uom: "",
  },
  onClick: () => {},
};

export default StockCard;
