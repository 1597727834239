import React from "react";
import { orderBy } from "lodash";
import { Modal, ActionButton, ProductCard, Keypad, CardGridList } from "../../components";
import { useSnackbar } from "notistack";
import { useQuery, useMutation } from "@apollo/client";
import { GET_CULTIVATE_PRODUCTS } from "../../apollo/queries";
import { CREATE_LINE_RECEPTION } from "../../apollo/mutations";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

const NewLineButton = React.forwardRef(({ parcel, disabled, onNewLine }, ref) => {
  const { data } = useQuery(GET_CULTIVATE_PRODUCTS);
  const [openProduct, setOpenProduct] = React.useState(false);
  const [openKeypad, setOpenKeypad] = React.useState(false);
  const [product, setProduct] = React.useState({});
  const [createLine] = useMutation(CREATE_LINE_RECEPTION, { ignoreResults: true, onCompleted: () => onNewLine() });
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  React.useImperativeHandle(ref, () => ({
    showDialog() {
      setOpenProduct(true);
    },
  }));

  const onAccept = async (quantity) => {
    setOpenKeypad(false);
    try {
      const variables = {
        stockPickingId: parseInt(id),
        stockMoveLine: {
          productId: product.id,
          parcelId: parcel.id,
          quantity,
        },
      };
      await createLine({ variables });
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
    }
  };

  const showProductDialog = () => {
    setOpenProduct(true);
  };
  const onCloseProduct = (product) => {
    setOpenProduct(false);

    if (product) {
      setProduct(product);
      setOpenKeypad(true);
    }
  };
  const onCloseKeypad = () => {
    setOpenKeypad(false);
  };

  return (
    <React.Fragment>
      <ActionButton action="success" disabled={disabled} onClick={showProductDialog}>
        Agregar Linea
      </ActionButton>
      <Modal open={openProduct} onClose={onCloseProduct} fullWidth maxWidth={false}>
        <CardGridList>
          {data && orderBy(data.products, ["priority"], ["desc"]).map((product) => <ProductCard key={product.id} product={product} onClick={() => onCloseProduct(product)} />)}
        </CardGridList>
      </Modal>
      <Modal open={openKeypad} onClose={onCloseKeypad} minWidth={888}>
        <Keypad onAccept={onAccept} uom={product.uom}>
          <ProductCard disableRipple product={product} />
        </Keypad>
      </Modal>
    </React.Fragment>
  );
});

NewLineButton.propTypes = {
  parcel: PropTypes.any,
  onNewLine: PropTypes.func,
};

NewLineButton.defaultProps = {
  onNewLine: () => {},
};

export default NewLineButton;
