import React from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import Modal from "./Modal";
import SignaturePad from "react-signature-canvas";
import { makeStyles } from "@material-ui/core/styles";
import ActionButton from "../buttons/ActionButton";

const SignModal = ({ open, onResult }) => {
  const useStyles = makeStyles((theme) => ({
    sigPad: {
      width: "100%",
      height: "500px",
      border: `1px solid ${theme.palette.grey[400]}`,
      marginBottom: theme.margin(0),
    },
  }));
  const classes = useStyles();
  const sigPad = React.useRef();
  return (
    <Modal open={open} minWidth={1000}>
      <SignaturePad canvasProps={{ className: classes.sigPad }} ref={sigPad} minWidth={1.3} maxWidth={1.3} />
      <Grid container>
        <Grid item sm style={{ marginRight: 20 }}>
          <ActionButton action="danger" onClick={() => onResult()} onLongClick={() => sigPad.current && sigPad.current.clear()} style={{ minWidth: 300 }}>
            Cancelar
          </ActionButton>
        </Grid>
        <Grid item sm>
          <ActionButton action="success" onClick={() => onResult(sigPad.current.getCanvas().toDataURL("image/png"))} style={{ minWidth: 300 }}>
            Aceptar
          </ActionButton>
        </Grid>
      </Grid>
    </Modal>
  );
};

SignModal.propTypes = {
  open: PropTypes.bool,
  onResult: PropTypes.func,
};

SignModal.defaultProps = {
  open: false,
  onResult: () => {},
};

export default SignModal;
