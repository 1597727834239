import React from "react";
import PropTypes from "prop-types";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";

const CardGridList = ({ cols, children, ...props }) => (
  <React.Fragment>
    {children && (
      <GridList cellHeight="auto" cols={cols} spacing={10} {...props}>
        {React.Children.map(children, (child) => (
          <GridListTile key={child} cols={1}>
            {React.cloneElement(child)}
          </GridListTile>
        ))}
      </GridList>
    )}
  </React.Fragment>
);

CardGridList.propTypes = {
  cols: PropTypes.number,
  children: PropTypes.node,
};

CardGridList.defaultProps = {
  cols: 2,
};

export default CardGridList;
