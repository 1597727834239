import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { ApolloProvider } from "@apollo/client";
import { SnackbarProvider, PrivateRoute } from "./components";
import { Redirect } from "react-router";
import theme from "./styles/theme";
import client from "./apollo";
import {
  LoginScreen,
  MenuScreen,
  ReceptionScreen,
  ReceptionDetailScreen,
  PaletScreen,
  PaletDetailScreen,
  SaleScreen,
  SaleDetailScreen,
  PurchaseScreen,
  PurchaseDetailScreen,
  CmrDetailScreen,
} from "./screens";

const AppComponent = () => {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={1}>
          <Router>
            <Switch>
              <PrivateRoute path="/menu" exact component={MenuScreen} />
              <PrivateRoute path="/receptions" exact component={ReceptionScreen} />
              <PrivateRoute path="/receptions/:id" exact component={ReceptionDetailScreen} />
              <PrivateRoute path="/palets" exact component={PaletScreen} />
              <PrivateRoute path="/palets/:id" exact component={PaletDetailScreen} />
              <PrivateRoute path="/sales" exact component={SaleScreen} />
              <PrivateRoute path="/sales/:id" exact component={SaleDetailScreen} />
              <PrivateRoute path="/purchases" exact component={PurchaseScreen} />
              <PrivateRoute path="/purchases/:id" exact component={PurchaseDetailScreen} />
              <PrivateRoute path="/cmr/:id" exact component={CmrDetailScreen} />
              <Route path="/login" exact component={LoginScreen} />
              <PrivateRoute path="*" exact component={() => <Redirect to="/menu" />} />
            </Switch>
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default AppComponent;
