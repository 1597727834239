import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

const styles = {
  containerWrapper: {
    width: "100%",
    height: 150,
    position: "relative",
    overflow: "hidden",
    overflowWrap: "break-word",
  },
  title: {
    width: "100%",
    fontSize: 45,
    fontWeight: 400,
    opacity: 0.91,
    transform: "translateY(-50%)",
    position: "absolute",
    top: "50%",
  },
};

const CarrierCard = ({ create, carrier, onClick }) => {
  const useStyles = makeStyles((theme) => ({
    ...styles,
    buttonColor: {
      backgroundColor: create ? theme.palette.success.main : theme.palette.grey[300],
      "&:hover": {
        backgroundColor: create ? theme.palette.success.dark : theme.palette.grey[400],
      },
      color: create ? "white" : "black",
    },
  }));
  const classes = useStyles();
  return (
    <Button variant="outlined" disableElevation {...{ onClick }} className={clsx(classes.button, classes.buttonColor, classes.containerWrapper)}>
      <Typography variant="h2" className={classes.title}>
        {carrier && carrier.name}
      </Typography>
    </Button>
  );
};

CarrierCard.propTypes = {
  create: PropTypes.bool,
  carrier: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onClick: PropTypes.func,
};

CarrierCard.defaultProps = {
  carrier: { name: "CREAR" },
  onClick: () => {},
};

export default CarrierCard;
