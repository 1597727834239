import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ButtonBase from "@material-ui/core/ButtonBase";
import { Typography } from "@material-ui/core";
import { Modal, Keyboard } from "../../components";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import { UPDATE_PURCHASE_ORDER_PARTNER_REF } from "../../apollo/mutations";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRight: `1px solid ${theme.palette.grey[400]}`,
    height: "100%",
    width: "100%",
  },
}));

const UpdatePartnerRef = React.forwardRef(({ onUpdatePartnerRef, children, ...props }, ref) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [openKeyboard, setOpenKeyboard] = React.useState(false);
  const [updatePurchaseOrderPartnerRef] = useMutation(UPDATE_PURCHASE_ORDER_PARTNER_REF, { ignoreResults: true, onCompleted: () => onUpdatePartnerRef() });
  const { id } = useParams();

  React.useImperativeHandle(ref, () => ({
    showDialog() {
      setOpenKeyboard(true);
    },
  }));

  const onKeyboardResult = (partnerRef) => {
    setOpenKeyboard(false);
    if (typeof partnerRef !== "undefined") updatePartnerRef(partnerRef);
  };

  const updatePartnerRef = async (partnerRef) => {
    try {
      const variables = {
        purchaseOrderId: id,
        partnerRef,
      };
      await updatePurchaseOrderPartnerRef({ variables });
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <ButtonBase onClick={() => setOpenKeyboard(true)} className={classes.button} {...props}>
        <Typography variant="h6">{children ? children : "Nº Albarán"}</Typography>
      </ButtonBase>
      <Modal open={openKeyboard} onClose={() => setOpenKeyboard(false)} minWidth={1580}>
        <Keyboard onResult={onKeyboardResult} defaultValue={children}>
          <Typography variant="subtitle2" style={{ marginBottom: 15 }}>
            Nº ALBARÁN
          </Typography>
        </Keyboard>
      </Modal>
    </React.Fragment>
  );
});

UpdatePartnerRef.propTypes = {
  onUpdatePartnerRef: PropTypes.func,
};

UpdatePartnerRef.defaultProps = {
  onUpdatePartnerRef: () => {},
};

export default UpdatePartnerRef;
