import React from "react";
import { orderBy } from "lodash";
import Grid from "@material-ui/core/Grid";
import { BackButton, ActionButton, DataTable } from "../../components";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { GET_RECEPTIONS } from "../../apollo/queries";
import { CREATE_RECEPTION } from "../../apollo/mutations";
import { formatDate } from "../../utils";

const columns = [
  { id: "name", label: "REFERENCIA", minWidth: 170 },
  {
    id: "datetime",
    label: "FECHA",
    minWidth: 100,
    format: (value) => {
      return formatDate(new Date(value));
    },
  },
  {
    id: "state",
    label: "ESTADO",
    minWidth: 100,
    format: (value) => {
      return {
        draft: "borrador",
        done: "realizado",
      }[value];
    },
  },
];

const ReceptionScreen = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { data, refetch, loading } = useQuery(GET_RECEPTIONS, { fetchPolicy: "cache-and-network", pollInterval: 60000 });
  const [addReception] = useMutation(CREATE_RECEPTION, {
    onCompleted: (result) => {
      history.push({ pathname: `/receptions/${result.reception}`, state: { create: true } });
    },
  });

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  const onRowClick = (row) => {
    history.push(`/receptions/${row.id}`);
  };

  const newReception = async () => {
    try {
      await addReception();
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid container>
          <Grid item sm style={{ marginRight: 20 }}>
            <BackButton />
          </Grid>
          <Grid item sm>
            <ActionButton onClick={newReception}>Registrar Entrada</ActionButton>
          </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 20 }}>
          <Grid item sm>
            <DataTable loading={loading} columns={columns} rows={data && orderBy(data.receptions, ["name"], ["desc"])} height="80.8vh" {...{ onRowClick }} />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ReceptionScreen;
