import React from "react";
import { orderBy } from "lodash";
import { Modal, ActionButton, ProductCard, Keypad, Keyboard, CardGridList } from "../../components";
import { useSnackbar } from "notistack";
import { useQuery, useMutation } from "@apollo/client";
import { GET_PACKAGING_PRODUCTS } from "../../apollo/queries";
import { CREATE_PURCHASE_ORDER_LINE } from "../../apollo/mutations";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

const NewLineButton = React.forwardRef(({ onNewLine, children, ...props }, ref) => {
  const { data } = useQuery(GET_PACKAGING_PRODUCTS);
  const { enqueueSnackbar } = useSnackbar();
  const [openProduct, setOpenProduct] = React.useState(false);
  const [openKeypad, setOpenKeypad] = React.useState(false);
  const [openKeyboard, setOpenKeyboard] = React.useState(false);
  const [product, setProduct] = React.useState({});
  const [quantity, setQuantity] = React.useState(0);
  const [createPurchaseLine] = useMutation(CREATE_PURCHASE_ORDER_LINE, { ignoreResults: true, onCompleted: () => onNewLine() });
  const { id } = useParams();

  React.useImperativeHandle(ref, () => ({
    showDialog() {
      setOpenProduct(true);
    },
  }));

  const onKeypadResult = (quantity) => {
    setOpenKeypad(false);

    if (product.tracking === "lot") {
      setOpenKeyboard(true);
      setQuantity(quantity);
    } else {
      createLine({ productId: product.id, quantity });
    }
  };

  const onKeyboardResult = (lot) => {
    setOpenKeyboard(false);
    if (lot) createLine({ productId: product.id, quantity, lot });
  };

  const onCloseProduct = (product) => {
    setOpenProduct(false);

    if (product) {
      setProduct(product);
      setOpenKeypad(true);
    }
  };

  const createLine = async (purchaseOrderLine) => {
    try {
      const variables = {
        purchaseOrderId: id,
        purchaseOrderLine,
      };
      await createPurchaseLine({ variables });
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <ActionButton action="success" onClick={() => setOpenProduct(true)} {...props}>
        {children}
      </ActionButton>
      <Modal open={openProduct} onClose={onCloseProduct} fullWidth maxWidth={false}>
        <CardGridList>
          {data && orderBy(data.products, ["priority"], ["desc"]).map((product) => <ProductCard key={product.id} product={product} onClick={() => onCloseProduct(product)} />)}
        </CardGridList>
      </Modal>
      <Modal open={openKeypad} onClose={() => setOpenKeypad(false)} minWidth={888}>
        <Keypad onAccept={onKeypadResult} uom="Unidades">
          <ProductCard disableRipple product={product} />
        </Keypad>
      </Modal>
      <Modal open={openKeyboard} onClose={() => setOpenKeyboard(false)} minWidth={1580}>
        <Keyboard onResult={onKeyboardResult} placeholder={product.tracking === "lot" ? "LOTE - S/N" : ""} required>
          <ProductCard disableRipple product={product} />
        </Keyboard>
      </Modal>
    </React.Fragment>
  );
});

NewLineButton.propTypes = {
  parcel: PropTypes.any,
  onNewLine: PropTypes.func,
};

NewLineButton.defaultProps = {
  onNewLine: () => {},
};

export default NewLineButton;
