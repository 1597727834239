import React from "react";
import { orderBy } from "lodash";
import Grid from "@material-ui/core/Grid";
import { BackButton, ActionButton, DataTable, PartnerModal } from "../../components";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { GET_PURCHASES } from "../../apollo/queries";
import { CREATE_PURCHASE_ORDER } from "../../apollo/mutations";
import { formatDate } from "../../utils";

const columns = [
  { id: "partnerRef", label: "Nº ALBARÁN", minWidth: 170 },
  {
    id: "dateOrder",
    label: "FECHA",
    minWidth: 100,
    format: (value) => {
      return formatDate(new Date(value));
    },
  },
  {
    id: "partnerId",
    label: "PROVEEDOR",
    minWidth: 100,
    format: (value) => {
      return value.name;
    },
  },
];

const PurchaseScreen = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [openPartner, setOpenPartner] = React.useState(false);
  const { data, refetch, loading } = useQuery(GET_PURCHASES, { fetchPolicy: "cache-and-network", pollInterval: 60000 });
  const [createPurchaseOrder] = useMutation(CREATE_PURCHASE_ORDER, {
    onCompleted: (result) => {
      history.push({ pathname: `/purchases/${result.id}`, state: { create: true } });
    },
  });

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  const onRowClick = (row) => {
    history.push(`/purchases/${row.id}`);
  };

  const newPurchase = async (partnerId) => {
    try {
      const variables = { partnerId };
      await createPurchaseOrder({ variables });
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
    }
  };

  const onPartnerResult = (partner) => {
    setOpenPartner(false);
    if (partner) newPurchase(partner.id);
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid container>
          <Grid item sm style={{ marginRight: 20 }}>
            <BackButton />
          </Grid>
          <Grid item sm>
            <ActionButton onClick={() => setOpenPartner(true)}>Crear compra</ActionButton>
          </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 20 }}>
          <Grid item sm>
            <DataTable loading={loading} columns={columns} rows={data && orderBy(data.purchases, ["name"], ["desc"])} height="80.8vh" {...{ onRowClick }} />
          </Grid>
        </Grid>
      </Grid>
      <PartnerModal vendor open={openPartner} onResult={onPartnerResult} />
    </React.Fragment>
  );
};

export default PurchaseScreen;
