import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { getContrastText } from "../../utils";

const styles = {
  subtitle: {
    position: "relative",
    top: -5,
    opacity: 0.67,
  },
  avatarContainer: {
    marginLeft: 6,
  },
  bodyContainer: {
    textAlign: "left",
    paddingLeft: 5,
    whiteSpace: "nowrap",
    width: "100%",
    overflow: "hidden",
  },
};

const PartnerCard = ({ partner, ...props }) => {
  const sourceColor = partner.color || (partner.parentId && partner.parentId.color);
  const color = getContrastText(sourceColor);
  const useStyles = makeStyles((theme) => ({
    ...styles,
    button: {
      width: "100%",
      height: 131,
      padding: 0,
      backgroundColor: sourceColor ? sourceColor : theme.palette.warning.main,
      "&:hover": {
        backgroundColor: sourceColor ? sourceColor : theme.palette.warning.dark,
      },
      color,
    },
    avatar: {
      height: 115,
      width: 115,
      border: `1px solid ${color}`,
      backgroundColor: "transparent",
      color,
      fontSize: 75,
    },
  }));
  const classes = useStyles();
  return (
    <Button variant="outlined" disableElevation className={classes.button} {...props}>
      <div className={classes.avatarContainer}>
        <Avatar variant="square" alt={partner.name} src={partner.image ? `data:image/png;base64, ${partner.image}` : undefined} className={classes.avatar}>
          {partner.name.substring(0, 1)}
        </Avatar>
      </div>
      <Grid container direction="column" className={classes.bodyContainer}>
        <Grid item>
          <Typography variant="body2">{partner.name}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" className={classes.subtitle}>
            {partner.country} {partner.city !== "" ? <span>({partner.city})</span> : ""}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );
};

PartnerCard.propTypes = {
  stock: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  onClick: PropTypes.func,
};

PartnerCard.defaultProps = {
  partner: {
    name: "",
    country: "",
    image: "",
  },
  onClick: () => {},
};

export default PartnerCard;
