const FIELDS = [
  {
    field: "carrierCompanyId",
    label: "Empresa de transporte",
    type: "carrier",
  },
  {
    field: "transportDni",
    label: "DNI del transportista",
    type: "str",
  },
  {
    field: "trailerPlate",
    label: "Semirremolque",
    type: "str",
  },
  {
    field: "tractorPlate",
    label: "Tractora",
    type: "str",
  },
  {
    field: "destinyStreet",
    label: "Dirección de destino",
    type: "str",
  },
  {
    field: "destinyCountry",
    label: "País de destino",
    type: "str",
  },
  {
    field: "temperature",
    label: "Temperatura",
    type: "int",
    uom: "ºC",
  },
  {
    field: "paletCount",
    label: "EuroPalets cargados",
    type: "int",
    uom: "palets",
  },
  {
    field: "paletCountReceived",
    label: "EuroPalets recibidos",
    type: "int",
    uom: "palets",
  },
];

export { FIELDS };
