import withStyles from "@material-ui/core/styles/withStyles";
import LinearProgress from "@material-ui/core/LinearProgress";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    border: `1px solid ${theme.palette.grey[400]}`,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

const ProgressBar = ({ ...props }) => <BorderLinearProgress color="primary" {...props} />;

export default ProgressBar;
