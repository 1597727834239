import React from "react";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { BackButton, SecureButton, PrinterButton, Paper, DataTable, SignButton } from "../../components";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { formatDate } from "../../utils";
import { useSnackbar } from "notistack";
import { GET_CMR } from "../../apollo/queries";
import CmrField from "./CmrField";
import { FIELDS } from "./Fields";
import { UPDATE_CMR, DELETE_CMR } from "../../apollo/mutations";

const columns = [
  { id: "name", label: "DESCRIPCIÓN", minWidth: 100 },
  { id: "ware", label: "MERCANCÍA", minWidth: 50 },
  { id: "size", label: "FORMATO", minWidth: 50 },
  { id: "packaging", label: "EMBALAJE", minWidth: 50 },
  { id: "quantity", label: "BULTOS", minWidth: 50 },
];

const useStyles = makeStyles((theme) => ({
  rightButton: {
    marginLeft: theme.margin(0),
  },
  root: {
    width: "100%",
    height: "55.5vh",
  },
  leftHeader: {
    borderRight: `1px solid ${theme.palette.grey[400]}`,
    minHeight: 50,
    padding: 12,
    textAlign: "center",
  },
}));

const CmrDetailScreen = () => {
  const { id } = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { data, loading, refetch } = useQuery(GET_CMR, {
    variables: { id },
    fetchPolicy: "cache-and-network",
    pollInterval: 30000,
  });

  const backButtonRef = React.useRef();
  const [deleteCMR] = useMutation(DELETE_CMR, { ignoreResults: true, onCompleted: () => backButtonRef.current.goBack() });
  const [updateField] = useMutation(UPDATE_CMR, { ignoreResults: true, onCompleted: () => refetch() });

  const onDeleteCMR = async () => {
    try {
      const variables = { id };
      await deleteCMR({ variables });
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
    }
  };

  const onSignCMR = async (signature) => {
    try {
      const variables = { id, inputCmr: { signature: signature.replace("data:image/png;base64,", "") } };
      await updateField({ variables });
      enqueueSnackbar("CMR firmado correctamente.", {
        variant: "success",
        anchorOrigin: { vertical: "top", horizontal: "center" },
        autoHideDuration: 1500,
      });
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid container>
          <Grid item sm>
            <BackButton ref={backButtonRef} />
          </Grid>
          {data && data.cmr.state === "signed" && (
            <Grid item sm className={classes.rightButton}>
              <PrinterButton
                pdfs={["consignee", "carrier", "black"].map((copy) => `agro_transport.cmr_report_document?options=%7B%22ids%22%3A%5B${id}%5D%2C%22copy%22%3A%22${copy}%22%7D`)}
              />
            </Grid>
          )}
          {data && data.cmr.state === "not_signed" && (
            <Grid item sm className={classes.rightButton}>
              <SignButton onSigned={onSignCMR} />
            </Grid>
          )}
        </Grid>
        <Grid container item style={{ marginTop: 20 }}>
          <Grid item sm>
            <Paper>
              <Grid container>
                <Grid item sm={3}>
                  <Typography variant="h6" className={classes.leftHeader}>
                    {data && data.cmr.name}
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography variant="h6" className={classes.leftHeader}>
                    {data && data.cmr.partnerId.name}
                  </Typography>
                </Grid>
                <Grid item sm={3}>
                  <Typography variant="h6" className={classes.leftHeader}>
                    {data && formatDate(new Date(data.cmr.date))}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 20 }}>
          <Grid item sm>
            <Paper className={classes.root}>
              {FIELDS.map((col, index) => (
                <Grid container spacing={2} key={index}>
                  {col.map((row) =>
                    Object.keys(row).map((key) => (
                      <Grid item sm={row[key].col} key={key}>
                        <CmrField id={data && data.cmr.id} field={key} dataField={row[key]} value={data && data.cmr[key]} uom={row[key].uom} onUpdate={() => refetch()} />
                      </Grid>
                    ))
                  )}
                </Grid>
              ))}
              <Grid container item style={{ margin: "5px 10px", width: "98.8%" }}>
                <Grid item sm>
                  <DataTable loading={loading} columns={columns} rows={data && data.cmr.cmrLines} height="32.8vh" />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid container item style={{ marginTop: 20 }}>
          <Grid item sm>
            <SecureButton
              acceptButton={{ name: "Eliminar", action: "danger" }}
              cancelButton={{ name: "Cancelar", action: "primary" }}
              message={`¿Seguro que desea eliminar el '${data && data.cmr.name}'?`}
              onResult={onDeleteCMR}
            >
              Eliminar
            </SecureButton>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CmrDetailScreen;
