import React from "react";
import { Modal, DataTable, ActionButton, Keypad, ProductCard, Keyboard } from "../../components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";
import { useMutation } from "@apollo/client";
import { UPDATE_PURCHASE_ORDER_LINE, DELETE_PURCHASE_ORDER_LINE } from "../../apollo/mutations";

const UpdateLineDataTable = ({ enabled, purchaseOrder, onLineUpdate, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [row, setRow] = React.useState(false);
  const [quantity, setQuantity] = React.useState(0);

  const [openKeypad, setOpenKeypad] = React.useState(false);
  const [openKeyboard, setOpenKeyboard] = React.useState(false);

  const [updatePurchaseLine] = useMutation(UPDATE_PURCHASE_ORDER_LINE, { ignoreResults: true, onCompleted: () => onLineUpdate() });
  const [deletePurchaseLine] = useMutation(DELETE_PURCHASE_ORDER_LINE, { ignoreResults: true, onCompleted: () => onLineUpdate() });

  const showDialog = (row) => {
    if (enabled) {
      setRow(row);
      setOpen(true);
    }
  };

  const updateLine = async (inputUpdatePurchaseOrderLine) => {
    try {
      const variables = {
        purchaseOrderLineId: row.id,
        inputUpdatePurchaseOrderLine,
      };
      await updatePurchaseLine({ variables });
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
    }
  };

  const deleteLine = async () => {
    try {
      const variables = { purchaseOrderLineId: row.id };
      await deletePurchaseLine({ variables });
      setOpen(false);
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
    }
  };

  const onKeypadResult = (quantity) => {
    if (row.productId.tracking === "lot") {
      setOpenKeyboard(true);
      setQuantity(quantity);
    } else {
      updateLine({ quantity });
    }
    setOpenKeypad(false);
  };

  const onKeyboardResult = (lot) => {
    setOpenKeyboard(false);
    if (lot) updateLine({ quantity, lot });
  };

  const onClose = (value) => {
    if (value === "delete") {
      deleteLine();
    } else if (value === "edit") {
      setOpenKeypad(true);
      setOpen(false);
    }
  };

  return (
    <React.Fragment>
      <DataTable {...props} onRowClick={showDialog} />
      <Modal open={open} onClose={() => setOpen(false)} minWidth={1000}>
        <Typography variant="subtitle2" style={{ textAlign: "center" }}>
          <p>
            {row && row.productId.displayName}
            <br />
            {row && row.quantity} UNIDADES {row && row.lot && `- (${row.lot})`}
          </p>
        </Typography>
        <Grid container spacing={2}>
          {purchaseOrder && purchaseOrder.state !== "done" && (
            <Grid item sm>
              <ActionButton action="danger" onClick={() => onClose("delete")} style={{ minWidth: 300 }}>
                Eliminar
              </ActionButton>
            </Grid>
          )}
          <Grid item sm>
            <ActionButton action="primary" onClick={() => onClose("edit")} style={{ minWidth: 300 }}>
              Editar
            </ActionButton>
          </Grid>
        </Grid>
      </Modal>
      <Modal open={openKeypad} onClose={() => setOpenKeypad(false)} minWidth={888}>
        <Keypad defaultValue={row.quantity} onAccept={onKeypadResult} uom={(row && row.uom) || ""}>
          <ProductCard disableRipple product={row && row.productId} />
        </Keypad>
      </Modal>
      <Modal open={openKeyboard} onClose={() => setOpenKeyboard(false)} minWidth={1580}>
        <Keyboard onResult={onKeyboardResult} defaultValue={(row && row.lot) || ""} placeholder={row && row.productId.tracking === "lot" ? "LOTE - S/N" : ""} required>
          <ProductCard disableRipple product={row && row.productId} />
        </Keyboard>
      </Modal>
    </React.Fragment>
  );
};

export default UpdateLineDataTable;
