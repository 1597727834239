import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ActionButton from "../buttons/ActionButton";
import ErrorModal from "../modals/ErrorModal";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  display: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[400]}`,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    width: 838,
  },
}));

const KEYS_MAP = [
  [7, 8, 9],
  [4, 5, 6],
  [1, 2, 3],
];

const Keypad = ({ onAccept, defaultValue, limitValue, uom, children }) => {
  const errorModal = React.useRef();
  const [value, setValue] = React.useState(defaultValue);
  const [firstClick, setfirstClick] = React.useState(true);
  const classes = useStyles();

  const onNumberClick = (key) => {
    if (firstClick) {
      setValue("".concat(key));
      setfirstClick(false);
    } else {
      if (value.length < 10) setValue(value.concat(key));
    }
  };

  const onDeleteClick = () => {
    setValue("0");
    setfirstClick(true);
  };

  const onBackClick = () => {
    setfirstClick(false);
    if (value.length > 1) setValue(value.substring(0, value.length - 1));
    else onDeleteClick();
  };

  const onConfirm = () => {
    if (limitValue && value > limitValue) {
      errorModal.current.openModal();
      setfirstClick(true);
    } else {
      onAccept(parseInt(value));
      onDeleteClick();
    }
  };

  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.header}>{children}</div>
        <Grid container style={{ height: "74vh" /*740*/ }}>
          <Grid container item>
            <Grid item sm={8}>
              <div className={classes.display}>
                <Typography variant="h4">
                  {value} <Typography variant="caption">{uom}</Typography>
                </Typography>
              </div>
            </Grid>
            <Grid item sm={4} style={{ height: "100%" }}>
              <ActionButton action="danger" onClick={onBackClick} onLongClick={onDeleteClick}>
                BORRAR
              </ActionButton>
            </Grid>
          </Grid>

          {KEYS_MAP.map((keys) => (
            <Grid key={keys} container item>
              {keys.map((key) => (
                <Grid key={key} item sm>
                  <ActionButton action="warning" onClick={() => onNumberClick(key)}>
                    {key}
                  </ActionButton>
                </Grid>
              ))}
            </Grid>
          ))}

          <Grid container item>
            <Grid item sm>
              <ActionButton action="warning" onClick={() => onNumberClick(0)}>
                0
              </ActionButton>
            </Grid>
            <Grid item sm>
              <ActionButton action="success" onClick={onConfirm}>
                ACEPTAR
              </ActionButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <ErrorModal ref={errorModal} error="¡No hay suficiente stock!" />
    </React.Fragment>
  );
};

Keypad.propTypes = {
  onAccept: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  limitValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  uom: PropTypes.string,
  children: PropTypes.node,
};

Keypad.defaultProps = {
  onAccept: () => {},
  defaultValue: "0",
  uom: "KG",
};

export default Keypad;
