import React from "react";
import { orderBy } from "lodash";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import { BackButton, ActionButton, PaletCard, ProgressBar, Paper, CardGridList } from "../../components";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useQuery, useMutation } from "@apollo/client";
import { GET_PALETS } from "../../apollo/queries";
import { CREATE_PALET } from "../../apollo/mutations";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "80.8vh",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "calc(50% - 200px)",
    width: 400,
    zIndex: 1,
  },
  emptyText: {
    position: "absolute",
    top: "45%",
    left: "calc(50% - 295px)",
    opacity: 0.85,
    width: 590,
    zIndex: 1,
  },
});

const PaletScreen = () => {
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { data, refetch, loading } = useQuery(GET_PALETS, { fetchPolicy: "cache-and-network", pollInterval: 60000 });
  const [createPalet] = useMutation(CREATE_PALET, {
    onCompleted: (result) => {
      history.push(`/palets/${result.createPalet}`);
    },
  });

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  const onCardClick = (card) => {
    history.push(`/palets/${card.id}`);
  };

  const newPalet = async () => {
    try {
      await createPalet();
    } catch (error) {
      enqueueSnackbar("Error en la conexión con el servidor", { variant: "error" });
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid container>
          <Grid item sm style={{ marginRight: 20 }}>
            <BackButton />
          </Grid>
          <Grid item sm>
            <ActionButton onClick={newPalet}>Crear Palet</ActionButton>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 20, position: "relative" }}>
          {loading && <ProgressBar color="primary" className={classes.progress} />}
          <Paper className={classes.root}>
            <CardGridList style={{ padding: 10 }}>
              {!loading && data && orderBy(data.palets, ["name"], ["desc"]).map((palet) => <PaletCard key={palet.id} palet={palet} onClick={onCardClick} showSaleState />)}
            </CardGridList>
            {!loading && data && data.palets.length <= 0 && (
              <Typography variant="h5" className={classes.emptyText}>
                No hay palets en almacén
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PaletScreen;
